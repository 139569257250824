var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-container',[_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"mr-16 blackHeader"},[_vm._v(" Kampanje ")]),_c('v-btn',{attrs:{"color":"primary","href":"/admin/campaign/new"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-plus")]),_vm._v(" Nova kampanja ")],1)],1)]),_c('v-container',[_c('v-row',[_c('v-data-table',{staticClass:"headerBase",staticStyle:{"width":"100%"},attrs:{"items":_vm.campaigns,"headers":_vm.headers,"footer-props":{
          'items-per-page-options': [10, 20, 50],
        }},on:{"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"white--text",attrs:{"color":item.state === 'ready' ? 'success' : '#008BCC'}},[_vm._v(" "+_vm._s(item.state === 'ready' ? 'Aktivno' : 'Završeno')+" ")])]}},{key:"item.campaign_type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.campaign_type[item.campaign_type])+" ")]}},{key:"item.test_type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.test_type.length !== _vm.testType.length ? 'Parcijalan' : 'Kompletan')+" ")]}},{key:"item.company",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.company.name)+" ")]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.created_at))+" ")]}},{key:"item.ends_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.until_end(item.ends_at))+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }