
































































































































































import API from '@/plugins/axios';
import { VForm } from '@/plugins/vuetify';
import Vue from 'vue';
import NumberCircle from '@/components/NumberCircle.vue';
import dayjs from 'dayjs';
import { testType } from '@/utils/data';
export default Vue.extend({
  name: 'AdminHome',
  components: {
    NumberCircle,
  },
  data: () => ({
    form: {
      position: '',
      state: 'ready',
      campaign_type: 'new_position',
      starts_at: dayjs().format('YYYY-MM-DD'),
      ends_at: dayjs()
        .add(1, 'month')
        .format('YYYY-MM-DD'),
      num_reports: '',
      num_candidates: '',
      company_id: '',
    },
    selected_test_type: [
      'Istrajnost',
      'Proaktivnost',
      'Odgovornost',
      'Inovativnost',
      'Efikasnost',
      'Veština komunikacije',
    ] as string[],
    companies: [] as { text: string; value: string }[],
    rules: {},
    validForm: false,
    isSent: false,
    today: dayjs().format('YYYY-MM-DD'),
    minStart: dayjs().format('YYYY-MM-DD'),
    campaign_types: [
      { text: 'Nova pozicija', value: 'new_position' },
      { text: 'Interni test', value: 'in_house' },
      { text: 'Profil komapnije', value: 'company_profile' },
    ],
    starts_at_open: false,
    ends_at_open: false,
    test_type: [...testType],
    isNew: false,
    cancelUrl: '',
    page: 1,
  }),
  computed: {
    completeTest() {
      return this.selected_test_type.length === this.test_type.length;
    },
    icon() {
      if (this.completeTest) return 'mdi-close-box';
      if (this.partialTest) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    partialTest() {
      const completeTest = this.completeTest as boolean;
      return this.selected_test_type.length > 0 && !completeTest;
    },
  },
  methods: {
    async register() {
      if (!(this.$refs.form as VForm).validate()) return;
      const form = {
        ...this.form,
        starts_at: dayjs(this.form.starts_at).isBefore(this.today)
          ? dayjs(this.today).toISOString()
          : dayjs(this.form.starts_at).toISOString(),
        ends_at: dayjs(this.form.ends_at).toISOString(),
        test_type: this.selected_test_type.length ? this.selected_test_type : this.test_type,
      };
      if (this.$route.params.campaign_id) {
        const { status } = await API.patch(`/administration/campaigns/${this.$route.params.campaign_id}/`, form);
        if (status === 200) {
          this.isSent = true;
          this.$router.push(`/admin/campaign/${this.$route.params.campaign_id}`);
        } else {
          console.error('something went wrong');
        }
      } else {
        const { status, data } = await API.post('/administration/campaigns/', form);
        if (status === 201) {
          this.isSent = true;
          this.$router.push(`/admin/campaign/${data.id}`);
        } else {
          console.error('something went wrong');
        }
      }
    },
    updateCampaignType(value: string) {
      if (value != 'company_profile') return;
      if (this.form.position.length) return;
      this.form.position = 'Profil kompanije';
    },
    isPopulated(error: string) {
      return (v: string) => !!v.length || error;
    },
    async selectAllTypes() {
      await this.$nextTick();
      if (!this.selected_test_type.length) {
        this.selected_test_type = [...this.test_type];
      } else {
        this.selected_test_type = [];
      }
    },
    async getData() {
      const { data } = (await API.get(`/administration/companies/?page=${this.page}&limit=10`)) as {
        data: {
          data: [];
          next_page?: number;
          result_count: number;
          additional_data: {
            total_candidates: number;
            total_finished_campaigns: number;
          };
        };
      };
      this.companies = [
        ...this.companies,
        ...data.data.map((c: { name: string; id: string }) => ({ text: c.name, value: c.id })),
      ];
      if (data.next_page) {
        this.page++;
        this.getData();
      }
    },
  },
  async mounted() {
    if (this.$route.params.campaign_id) {
      this.cancelUrl = `/admin/campaign/${this.$route.params.campaign_id}`;
      const { data } = await API.get(`/administration/campaigns/${this.$route.params.campaign_id}/`);
      this.form = { ...data, num_reports: `${data.num_reports}` };
      this.form.company_id = data.company.id;
      this.companies = [{ value: this.form.company_id, text: data.company.name }];
      this.minStart = dayjs(this.today).isBefore(this.form.starts_at) ? this.today : this.form.starts_at;
    } else if (this.$route.params.id) {
      this.cancelUrl = `/admin/company/${this.$route.params.id}`;
      this.form.company_id = this.$route.params.id;
      const { data } = await API.get(`/administration/companies/${this.$route.params.id}/`);
      this.companies = [{ value: this.form.company_id, text: data.name }];
    } else {
      this.isNew = true;
      this.getData();
      this.cancelUrl = `/`;
    }

    this.rules = {
      position: [this.isPopulated('Ime kampanje mora biti popunjeno')],
      num_reports: [this.isPopulated('Broj izveštaja mora biti popunjen')],
    };
    if (this.$route.params.campaign_id) {
      await this.$nextTick();
      !(this.$refs.form as VForm).validate();
    }
  },
});
