
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import API from '@/plugins/axios';
import ReportBarChart from '@/components/ReportBarChart.vue';
import { characteristics, template } from '@/utils/data';
import { characteristicsReport, templateReport } from '@/utils/reportData';
import Vue from 'vue';
import dayjs from 'dayjs';
import Axios from 'axios';
export default Vue.extend({
  name: 'PsychReportPage',
  props: ['applications', 'campaign', 'psych'],
  data: () => ({
    today: new Date(),
  }),
  components: {
    ReportBarChart,
  },
  computed: {
    contextPages() {
      return Math.ceil(this.applications.length / 20);
    },
    numberOfPages() {
      return (
        3 +
        (this as any).contextPages +
        this.applications.reduce((prev: number, next: { conclusion: unknown }) => {
          return prev + 2 + (next.conclusion ? 1 : 0);
        }, 0)
      );
    },
    applicationsContext() {
      const pages = [] as any[];
      for (let i = 0; i < this.contextPages; i++) {
        pages.push(this.applications.slice(i * 20, (i + 1) * 20));
      }
      return pages;
    },
    applicationPageStart() {
      return this.applications.reduce(
        (
          prev: {
            candidates: {
              [key: string]: number;
            };
            lastPage: number;
          },
          next: { conclusion: unknown; id: number }
        ) => {
          prev.candidates[next.id] = prev.lastPage + 1;
          prev.lastPage = prev.lastPage + 2 + (next.conclusion ? 1 : 0);
          return prev;
        },
        {
          candidates: {},
          lastPage: 3 + (this as any).contextPages,
        }
      );
    },
  },
  methods: {
    convertDate(date: string) {
      return dayjs(date).format('DD.MM.YYYY.');
    },
    async dlReport(print = false) {
      const reportBody = document.getElementById('reportBody');
      const links = Array.from(document.querySelectorAll('link'))
        .filter(l => l.href.includes('.css'))
        .map(l => l.href);

      const data = await Promise.all(links.map(async l => (await Axios.get(l)).data));
      const report = `
      <html id="innerHTML">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
        <meta name="pdfkit-page-size" content="A4"/>
        <meta name="pdfkit-margin-bottom" content="0"/>
        <meta name="pdfkit-margin-left" content="0"/>
        <meta name="pdfkit-margin-right" content="0"/>
        <meta name="pdfkit-margin-top" content="0"/>
        <meta name="pdfkit-orientation" content="Portrait"/>
        <style>
          ${data.join(' ')}
        </style>
        <style>
          ${Array.from(document.querySelectorAll('style'))
            .map(s => s.innerHTML)
            .join(' ')}
        </style>
        <style>
          @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
          body { zoom: 120%; }
          html, body {
            font-family: 'Roboto', sans-serif;
          }
          @page {
  margin: 10%;

  @top-center {
    font-family: sans-serif;
    font-weight: bold;
    font-size: 2em;
    content: counter(page);
  }
}
.reportPage {
  display: block !important;
}
        </style>
      </head>
      <body class="reportPage">
        ${reportBody?.outerHTML as string}
      </body>
      </html>
      `;
      const blob = new Blob([report], { type: 'text/html;charset=utf-8' });
      const form = new FormData();
      form.append('filename', 'file');
      form.append('report', blob);
      const { request } = await API.post('/psych/reports/generate/', form, {
        responseType: 'blob',
      });
      const filename = `${this.campaign.company.name} - ${this.campaign.position}.pdf`;

      // The actual download
      const newBlob = new Blob([request.response], { type: 'application/pdf' });
      const href = window.URL.createObjectURL(newBlob);
      if (!print) {
        const link = document.createElement('a');
        link.href = href;
        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
      } else {
        window.open(href, '_blank');
      }
    },
    characteristicsData(application: { questioner: { personality: { [key: string]: number } } }) {
      const personality = application.questioner.personality;
      const data = [] as {}[];
      Object.entries(characteristicsReport).forEach(([key, values]) => {
        const percent = personality[`${key}_perc`];
        const title = characteristics.find(c => c.key === key)?.name;
        let score = '';
        let scoreDesc = '';
        let to = '';
        let text = '';

        Object.keys(values).forEach(key => {
          if (percent > Number(key)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const val = values as any;
            score = val[key].title;
            to = val[key].to;
            scoreDesc = `${key} - ${val[key].to}%`;
            text = val[key].text;
          }
        });

        data.push({
          title,
          score,
          scoreDesc,
          to,
          text,
        });
      });
      return data;
    },
    templateData(application: { questioner: { dimensions: { [key: string]: number } } }) {
      const dimensions = application.questioner.dimensions;
      const data = [] as {}[];
      Object.entries(templateReport).forEach(([key, values]) => {
        const percent = dimensions[`${key}_perc`];
        const title = template.find(c => c.key === key)?.name;
        let score = '';
        let to = '';
        let text = '';

        Object.keys(values).forEach(key => {
          if (percent > Number(key)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            score = (values as any)[key].title;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            to = (values as any)[key].to;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            text = (values as any)[key].text;
          }
        });

        data.push({
          title,
          score,
          to,
          text,
        });
      });
      return data;
    },
  },
});
