












































































































































import API from '@/plugins/axios';
import Vue from 'vue';
import BarChart from '@/components/BarChart.vue';
import LineChart from '@/components/LineChart.vue';
import { characteristics, criteria, template, vocationalQualifications } from '@/utils/data';
export default Vue.extend({
  name: 'Application',
  components: {
    BarChart,
    LineChart,
  },
  data: () => ({
    application: {
      candidate: {},
      questioner: {},
    },
    characteristics: [...characteristics],
    template: [...template],
    criteria: [...criteria],
    vocational_qualifications: [...vocationalQualifications],
    charBarChart: true,
    tempBarChart: true,
    criteriaBarChart: false,
  }),
  computed: {
    charChartData() {
      const labels = this.characteristics.map(c => c.name);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const app = this.application as any;
      return {
        labels,
        datasets: [
          Object.keys(app.candidate).length
            ? {
                label: `${app.candidate.forename} ${app.candidate.surname}`,
                backgroundColor: '#E74C3C',
                borderColor: '#E74C3C',
                fill: false,
                lineTension: 0,
                minBarLength: 2,
                data: Object.entries(app.questioner.personality)
                  .map(([key, value]) => {
                    return key.includes('_perc') && value;
                  })
                  .filter(v => !!v),
              }
            : {},
        ],
      };
    },
    tempChartData() {
      const labels = this.template.map(c => c.name);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const app = this.application as any;
      return {
        labels,
        datasets: [
          Object.keys(app.candidate).length
            ? {
                label: `${app.candidate.forename} ${app.candidate.surname}`,
                backgroundColor: '#16A085',
                borderColor: '#16A085',
                fill: false,
                lineTension: 0,
                minBarLength: 2,
                data: Object.entries(app.questioner.dimensions)
                  .map(([key, value]) => {
                    return key.includes('_perc') && value;
                  })
                  .filter(v => !!v),
              }
            : {},
        ],
      };
    },
    criteriaChartData() {
      const labels = this.criteria.map(c => c.name);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const app = this.application as any;

      return {
        labels,
        datasets: [
          Object.keys(app.candidate).length
            ? {
                label: `${app.candidate.forename} ${app.candidate.surname}`,
                backgroundColor: '#9B59B6',
                borderColor: '#9B59B6',
                fill: false,
                lineTension: 0,
                minBarLength: 2,
                data: Object.entries(app.questioner.decisions)
                  .map(([key, value]) => {
                    return key.includes('_perc') && value;
                  })
                  .filter(v => !!v),
              }
            : {},
        ],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      };
    },
  },
  async mounted() {
    const { data } = await API.get(`/psych/applications/${this.$route.params.id}/`);
    this.application = data;
  },
});
