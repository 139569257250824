
















































import API from '@/plugins/axios';
import Vue from 'vue';
import PsychReportPage from './ReportPage.vue';
export default Vue.extend({
  name: 'PsychReport',
  components: {
    PsychReportPage,
  },
  data: () => ({
    applications: [] as unknown[],
    dialog: false,
    conclusion: '',
    dialogId: -1,
    campaign: null as unknown,
    psych: null as unknown,
  }),
  computed: {},
  methods: {
    async saveConclusion() {
      const { status } = await API.post(`/psych/applications/${this.dialogId}/conclude/`, {
        conclusion: this.conclusion,
      });

      if (status === 200) {
        const app = (this.applications as Array<{ id: number; conclusion: string }>).find(
          app => app.id === this.dialogId
        );
        if (app) {
          app.conclusion = this.conclusion;
          this.applications = [...this.applications];
        }
        this.cancelConclusion();
      }
    },
    cancelConclusion() {
      this.dialog = false;
      this.dialogId = -1;
      this.conclusion = '';
    },
    openDialog(id: number) {
      this.dialogId = id;
      this.conclusion =
        (this.applications as Array<{ id: number; conclusion: string }>).find(app => app.id === id)?.conclusion || '';
      this.dialog = true;
    },
    async removeFromReport(id: number) {
      const { status } = await API.post(`/psych/applications/${this.$route.params.id}/deselect/`, {
        application_ids: [id],
      });
      if (status === 200) {
        const index = this.applications.indexOf((el: { id: number }) => el.id === id);
        this.applications.splice(index, 1);
        this.applications = [...this.applications];
      }
    },
  },
  async mounted() {
    const { data, status } = await API.get(`/psych/campaigns/${this.$route.params.id}/applications/selected/`);
    if (status === 200) {
      this.applications = [...data];
    }
    const { data: campaign, status: campaignStatus } = await API.get(`/psych/campaigns/${this.$route.params.id}/`);
    if (campaignStatus === 200) {
      this.campaign = campaign;
    }
    const { data: psych, status: psychStatus } = await API.get(`/psych/self/`);
    if (psychStatus === 200) {
      this.psych = psych;
    }
  },
});
