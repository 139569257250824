

























































































import API, { BASE_URL } from '@/plugins/axios';
import Vue from 'vue';
export default Vue.extend({
  name: 'CompanyList',
  data: () => ({
    BASE_URL: BASE_URL,
    companies: [],
    page: 1,
    total_companies: 0,
    total_candidates: 0,
    total_finished_campaigns: 0,
  }),
  computed: {},
  methods: {
    async getData() {
      const { data } = (await API.get(`/administration/companies/?page=${this.page}&limit=10`)) as {
        data: {
          data: [];
          next_page?: number;
          result_count: number;
          additional_data: {
            total_candidates: number;
            total_finished_campaigns: number;
          };
        };
      };
      this.companies = [...this.companies, ...data.data];
      if (data.next_page) {
        this.page++;
        this.getData();
      }
      this.total_companies = data.result_count;
      this.total_candidates = data.additional_data.total_candidates;
      this.total_finished_campaigns = data.additional_data.total_finished_campaigns;
    },
  },
  async mounted() {
    await this.getData();
  },
});
