





















































































import Question from '@/components/Question.vue';
import API from '@/plugins/axios';
import Vue from 'vue';
import { mapGetters } from 'vuex';
export default Vue.extend({
  components: { Question },
  computed: {
    ...mapGetters(['questions', 'answers']),
    pageQuestions() {
      return (this.questions as []).slice(
        (this.currentPage - 1) * this.items_per_page,
        (this.currentPage - 1) * this.items_per_page + this.items_per_page
      );
    },
    maxPages() {
      return Math.ceil((this.questions as []).length / this.items_per_page);
    },
    progressPercentage() {
      return Math.ceil((this.answers.filter((a: {} | null) => !!a).length / this.questions.length) * 100);
    },
    availablePages(): number {
      const answers = (this.answers as []).filter((a: {} | null) => !!a).length;
      return Math.min(
        Math.ceil(answers / this.items_per_page) + (answers % this.items_per_page === 0 ? 1 : 0),
        this.maxPages as number
      );
    },
    allDone() {
      return this.answers.filter((a: {} | null) => !!a).length === (this.questions as []).length;
    },
    prevPageAvailable() {
      return this.currentPage > 1;
    },
    nextPageAvailable() {
      return (this.availablePages as number) > this.currentPage;
    },
  },
  data: () => ({
    items_per_page: 4,
    currentPage: 1,
    dialog: false,
    timeout: (0 as unknown) as NodeJS.Timeout,
    finished: false,
  }),
  methods: {
    goToPrevious() {
      if (this.prevPageAvailable) {
        this.currentPage--;
      }
    },
    goToNext() {
      if (this.nextPageAvailable) {
        this.currentPage++;
      }
    },
    async submit() {
      await API.put(`candidate/questioners/${this.$route.params.questionar_id}/finalize/`);
      await API.patch(`candidate/applications/${this.$route.params.application_id}/submit/`, {
        questioner_id: this.$route.params.questionar_id,
      });
      await this.$store.dispatch('clearQuestions');
      this.finished = true;
    },
    stopTest() {
      this.dialog = true;
    },
    async sendPing() {
      this.timeout = setTimeout(async () => {
        await API.put(`/candidate/questioners/${this.$route.params.questionar_id}/ping/`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this as any).sendPing();
      }, 15000);
    },
  },
  async mounted() {
    if (`${this.$store.state.Test.active_questioner}` !== `${this.$route.params.questionar_id}`) {
      const { data } = await API.get(`candidate/questioners/${this.$route.params.questionar_id}/`);
      if (data.is_finished) {
        this.$router.push('/');
        this.$store.dispatch('clearQuestions');
        this.$store.dispatch('setActive', null);
      } else {
        this.$store.dispatch('setActive', this.$route.params.questionar_id);
        await this.$store.dispatch('setQuestions', data);
        this.currentPage = this.availablePages as number;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any).sendPing();
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
});
