









































































































import API, { BASE_URL } from '@/plugins/axios';
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { dateSort } from '@/utils/utils';
import { testType } from '@/utils/data';
dayjs.extend(relativeTime);
dayjs.locale('sr');
export default Vue.extend({
  name: 'AdminCompany',
  data: () => ({
    BASE_URL: BASE_URL,
    company: {},
    campaigns: [],
    headers: [
      { text: 'Kampanja', value: 'position' },
      { text: 'Tip kampanje', value: 'campaign_type' },
      { text: 'Tip testa', value: 'test_type' },
      { text: 'Datum', value: 'created_at', sort: dateSort },
      { text: 'Status', value: 'state' },
      { text: 'Do kraja', value: 'ends_at', sort: dateSort },
    ],
    testType: [...testType],
    page: 1,
    deleteDialog: false,
    campaign_type: {
      new_position: 'Nova pozicija',
      internal_test: 'Interni test',
      company_profile: 'Profil kompanije',
    },
  }),
  computed: {},
  methods: {
    formatedDate(date: string) {
      return dayjs(date).format('DD/MM/YY');
    },
    until_end(end: string) {
      return dayjs().to(end, true);
    },
    rowClicked({ id }: { id: number }) {
      this.$router.push(`/admin/campaign/${id}`);
    },
    async deleteCompany() {
      const { status } = await API.delete(`/administration/companies/${this.$route.params.id}/`);
      if (status === 200) {
        this.deleteDialog = false;
        this.$router.push('/');
      }
    },
    async getData() {
      const { data } = (await API.get(
        `/administration/companies/${this.$route.params.id}/campaigns/?page=${this.page}&limit=10`
      )) as {
        data: { data: []; next_page?: number };
      };
      this.campaigns = [...this.campaigns, ...data.data];
      if (data.next_page) {
        this.page++;
        this.getData();
      }
    },
  },
  async mounted() {
    try {
      const { data } = await API.get(`/administration/companies/${this.$route.params.id}/`);
      this.company = { ...data };
      await this.getData();
    } catch {
      this.$router.push('/');
    }
  },
});
