














import API from '@/plugins/axios';
import Vue from 'vue';
export default Vue.extend({
  name: 'ApplicationSubmitOrNew',
  methods: {
    useNew() {
      this.$router.push(`/test/${this.$route.params.application_id}/new`);
    },
    async useOlder() {
      const q = this.$store.state.Test.questioners.find((q: { is_finished: boolean }) => q.is_finished);

      await API.patch(`candidate/applications/${this.$route.params.application_id}/submit/`, {
        questioner_id: q.id,
      });
      this.$router.push('/test/application_sent');
    },
  },
});
