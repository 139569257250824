
































import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    showHeader: true,
    prevRoute: '/',
  }),
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    goBack() {
      // eslint-disable-next-line no-debugger
      // debugger;
      this.$router.back();
    },
  },
  watch: {
    $route() {
      this.prevRoute = this.$route.meta.prev;
      this.showHeader = this.$route.path !== '/psych';
    },
  },
  mounted() {
    this.prevRoute = this.$route.meta.prev;
    this.showHeader = this.$route.path !== '/psych';
  },
});
