


























import Vue from 'vue';
export default Vue.extend({
  name: 'ReportBarChart',
  props: ['data', 'orange'],
  methods: {},
});
