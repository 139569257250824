




































import { BASE_URL } from '@/plugins/axios';
import Axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  name: 'PasswordReset',
  data: () => ({
    form: {
      password: '',
      passwordConfirmation: '',
    },
    rules: {},
    validForm: false,
    formSent: false,
  }),
  methods: {
    async passwordReset() {
      try {
        await Axios.post(`${BASE_URL}/auth/reset_password/${this.$route.params.uuid}/`, {
          password: this.form.password,
        });
        this.formSent = true;
      } catch (e) {
        console.error(e);
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!v.length || error;
    },
    passwordsAreEqual() {
      return (v: string) => v === this.form.password || 'Lozinka i ponovljena lozinka moraju biti iste';
    },
  },
  mounted() {
    this.rules = {
      password: [
        this.isPopulated('Lozinka mora biti popunjena'),
        (v: string) => /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(v) || 'Lozinka mora da ima slova i brojeve',
      ],
      passwordConfirmation: [this.isPopulated('Ponovljena lozinka mora biti popunjena'), this.passwordsAreEqual()],
    };
  },
});
