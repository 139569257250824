
































import API from '@/plugins/axios';
import Vue from 'vue';
import NumberCircle from './NumberCircle.vue';
export default Vue.extend({
  name: 'Question',
  components: { NumberCircle },
  props: {
    question: String,
    answer_choices: Array,
    questioner_id: String,
    id: Number,
    answer: Object,
  },
  methods: {
    async updateAnswer(number: string) {
      const data = {
        question_id: this.id,
        answer: Number(number),
      };
      if (this.answer) {
        await API.patch(`candidate/questioners/${this.questioner_id}/answers/`, data);
      } else {
        await API.post(`candidate/questioners/${this.questioner_id}/answers/`, data);
      }
      await this.$store.dispatch('updateAnswer', data);
    },
  },
});
