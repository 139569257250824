






































































































































































import API, { BASE_URL } from '@/plugins/axios';
import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Application } from '@/types';
dayjs.extend(relativeTime);
dayjs.locale('sr');
export default Vue.extend({
  name: 'AdminCampaign',
  data: () => ({
    BASE_URL: BASE_URL,
    campaign: {},
    applications: [] as Application[],
    headers: [
      {
        text: 'Ime i prezime',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      { text: 'Status', value: 'is_completed' },
    ],
    page: 1,
    deleteDialog: false,
    finishDialog: false,
    campaign_type: {
      new_position: 'Nova pozicija',
      internal_test: 'Interni test',
      company_profile: 'Profil kompanije',
    },
  }),
  computed: {},
  methods: {
    candidateName: (candidate: Application['candidate']) => `${candidate.forename} ${candidate.surname}`,
    formatedDate(date: string) {
      return dayjs(date).format('DD/MM/YY');
    },
    until_end_days(end: string) {
      const until_end = dayjs(end).diff(dayjs(), 'day');
      return Math.max(until_end, 0);
    },
    campaign_url(link: string) {
      return `${window.location.origin}/auth/${link}`;
    },
    copy() {
      const content = this.campaign_url((this.campaign as { url: string }).url);
      navigator.clipboard.writeText(content).catch(() => {
        const textArea = document.createElement('textarea');
        textArea.classList.add('textarea');

        textArea.value = content;
        textArea.textContent = content;

        document.body.appendChild(textArea);

        const selection = document.getSelection();
        const range = document.createRange();
        range.selectNode(textArea);
        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);
          try {
            document.execCommand('copy');
            selection.removeAllRanges();
          } catch (err) {
            console.error(err);
          }
        }

        document.body.removeChild(textArea);
      });
    },
    async finishCampaign() {
      const { status } = await API.post(`/administration/campaigns/${this.$route.params.id}/finish/`);
      if (status === 200) {
        this.finishDialog = false;
        this.getCampaignData();
      }
    },
    async deleteCampaign() {
      const { status } = await API.delete(`/administration/campaigns/${this.$route.params.id}/`);
      if (status === 200) {
        this.deleteDialog = false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.$router.push(`/admin/company/${(this.campaign as any).company.id}`);
      }
    },
    async getCampaignData() {
      try {
        const { data } = await API.get(`/administration/campaigns/${this.$route.params.id}/`);
        this.campaign = { ...data };
      } catch {
        this.$router.push('/');
      }
    },
    async getData() {
      const { data: applications } = (await API.get(
        `/administration/campaigns/${this.$route.params.id}/applications/?page=${this.page}&limit=10`
      )) as { data: { data: Application[]; next_page?: number } };
      this.applications = [
        ...this.applications,
        ...applications.data.map(a => ({
          ...a,
          name: this.candidateName(a.candidate),
          email: a.candidate.email,
        })),
      ];
      if (applications.next_page) {
        this.page++;
        this.getData();
      }
    },
  },
  async mounted() {
    await this.getCampaignData();
    await this.getData();
  },
});
