












































































































































import Vue from 'vue';
import { VForm } from '@/plugins/vuetify';
import API, { BASE_URL } from '@/plugins/axios';
import NumberCircle from '@/components/NumberCircle.vue';
export default Vue.extend({
  components: { NumberCircle },
  name: 'Register',
  data: () => ({
    form: {
      email: '',
      password: '',
      passwordConfirmation: '',
      forename: '',
      parent_name: '',
      surname: '',
      birth_year: '',
      id_card_no: '',
      gender: '',
      education_level: '',
      vocation: '',
      last_education: '',
      contact_phone: '',
    },
    rules: {},
    validForm: false,
    education_levels: [
      { text: '(1) Osnovno obrazovanje i vaspitanje', value: 'Osnovno obrazovanje i vaspitanje' },
      { text: '(1) Osnovno obrazovanje odraslih', value: 'Osnovno obrazovanje odraslih' },
      { text: '(1) Osnovno muzičko/baletsko obrazovanje', value: 'Osnovno muzičko/baletsko obrazovanje' },
      {
        text: '(3) Srednje stručno obrazovanje (3 godine)',
        value: 'Srednje stručno obrazovanje u trajanju od tri godine',
      },
      { text: '(3) Neformalno obrazovanje odraslih', value: 'Neformalno obrazovanje odraslih (min 960 sati obuke)' },
      {
        text: '(4) Srednje obrazovanje (4 godine)',
        value: 'Srednje obrazovanje u trajanju od četiri godine (stručno, umetničko, gimnazijsko)',
      },
      { text: '(5) Majstorsko i specijalističko obrazovanje', value: 'Majstorsko i specijalističko obrazovanje' },
      { text: '(6.1) Osnovne akademske studije (OAS, 180 ESPB)', value: 'Osnovne akademske studije(OAS, 180 ESPB)' },
      { text: '(6.1) Osnovne stukovne studije (OSS, 180 ESPB)', value: 'Osnovne strukovne studije (OSS, 180 ESPB)' },
      { text: '(6.2) Osnovne akademske studije (OAS, 240 ESPB)', value: 'Osnovne akademske studije(OAS, 240)' },
      {
        text: '(6.2) Specijalističke strukovne studije I stepena (SSS, 240 ESPB)',
        value:
          'Specijalističke strukovne studije I stepena(SSS, 180+60 ESPB,stečene u skladu sa Zakonom o visokom obrazovanju koji je na,snazi od 07.10.2017.)',
      },
      {
        text: '(7.1) Integrisane akademske studije (IAS, MAX 360 ESPB)',
        value: 'Integrisane akademske studije (IAS, maks. 360 ESPB)',
      },
      {
        text: '(7.1) Master akademske studije (MAS, 300 ESPB)',
        value: 'Master akademske studije (MAS,180+120 ili 240+60 ESPB)',
      },
      { text: '(7.1) Master strukovne studije (MSS, 120 ESPB)', value: 'Master strukovne studije (MSS, 120 ESPB)' },
      {
        text: '(7.2) Specijalističke akademske studije (SAS, 60 ESPB)',
        value: 'Specijalističke akademske studije (SAS, 60 ESPB)',
      },
      { text: '(8) Doktorske studije (DS, 180 ESPB)', value: 'Doktorske studije (DS, 180 ESPB)' },
    ],
    isSent: false,
  }),
  methods: {
    async register() {
      if (!(this.$refs.form as VForm).validate()) return;
      const { status } = await API.patch(`${BASE_URL}/candidate/self/`, {
        password: this.form.password,
        forename: this.form.forename,
        surname: this.form.surname,
        birth_year: this.form.birth_year,
        id_card_no: this.form.id_card_no,
        gender: this.form.gender,
        education_level: this.form.education_level,
        vocation: this.form.vocation,
        last_education: this.form.last_education,
        contact_phone: this.form.contact_phone,
        parent_name: this.form.parent_name,
      });
      if (status === 200) {
        this.isSent = true;
      } else {
        console.error('something went wrong');
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!`${v}`.length || error;
    },
    passwordsAreEqual() {
      return (v: string) => v === this.form.password || 'Lozinka i ponovljena lozinka moraju biti iste';
    },
  },
  async mounted() {
    const { data } = await API.get('/candidate/self/');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { applications, ...formData } = data;
    this.form = {
      ...this.form,
      email: this.$store.getters.userData.email,
      ...formData,
    };

    this.rules = {
      password: [
        this.isPopulated('Lozinka mora biti popunjena'),
        (v: string) => /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(v) || 'Lozinka mora da ima slova i brojeve',
      ],
      passwordConfirmation: [this.isPopulated('Lozinka mora biti popunjena'), this.passwordsAreEqual()],
      forename: [this.isPopulated('Ime mora biti popunjeno')],
      parent_name: [this.isPopulated('Ime jednog od roditelja mora biti popunjeno')],
      surname: [this.isPopulated('Prezime mora biti popunjeno')],
      birth_year: [
        this.isPopulated('Godina rođenja mora biti popunjena'),
        (v: string) =>
          (Number.isInteger(Number(v)) &&
            Number(v) < new Date().getFullYear() &&
            Number(v) > new Date().getFullYear() - 100) ||
          'Godina rođenja mora biti validna',
      ],
      id_card_no: [this.isPopulated('Broj lične karte mora biti popunjen')],
      gender: [this.isPopulated('Pol mora biti izabran')],
      education_level: [this.isPopulated('Stručna sprema mora biti izabrana')],
      vocation: [this.isPopulated('Zanimanje mora biti popunjeno')],
      last_education: [this.isPopulated('Poslednja završena škola mora biti popunjena')],
      contact_phone: [this.isPopulated('Telefon mora biti popunjen')],
    };
  },
});
