





















import Vue from 'vue';
export default Vue.extend({
  name: 'NumberCircle',
  data: () => ({}),
  props: {
    number: String,
    size: String,
    active: Boolean,
  },
});
