export const characteristicsReport = {
  IST: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ovi skorovi mogu da ukazuju na neku vrstu afektivne izolacije, pa i psihopatologiju. Vremenom, ove
      osobe mogu postati vrlo rigidne. Neće menjati svoju strategiju ni ako ona ne daje očekivane rezultate
      u dužem vremenskom periodu. Mogu postati maladaptivne kod čestih promena. Takođe, mogu imati
      lošu introspekciju i slabiji uvid u sebe, svoje kapacitete i situaciju u kojoj se nalaze
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da su vrlo istrajne. Veoma su marljive i spremne da naporno rade uprkos
      teškoćama i frustraciji. Energični su, veruju u ono što rade i to ih motiviše. Retko imaju strah od
      odbijanja i neprihvatanja. Preuzimaju odgovornost za svoje odluke. Drugi ih vide kao nezavisne i
      nepodložne socijalnim pritiscima. Stabilni su i otporniji na stres.
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ovo su uglavnom vredne osobe koje relativno dobro podnose pritisak u radu. Uglavnom su nezavisne
      u razmišljanju, iako nisu potpune “imune” na tuđa mišljenja i socijalni pritisak. Sposobne su da sebi
      postave cilj koji će ih usmeravati u aktivnostima. Odgovorne su i strpljive. Imaju granicu do koje mere
      treba uložiti trud i neće se žrtvovati po svaku cenu. Dobro se snalaze u socijalnim kontaktima.
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Ove osobe neće biti preterano istrajne u tome što rade i verovatno im je potreban jasno definsan cilj
      kao eksterni motivator. Sigurno je da neće gubiti previše vremena, a ni resursa, ako procene da to ne
      vredi truda. Češće će menjati strategije i verovatno tražiti prečice. Međutim, ukoliko nađu pravi način
      za ostvarenje cilja, svakako će mu se i posvetiti na pravi način i sa dovoljnom dozom istrajnosti.
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da nisu istrajne. Ne ulažu dodatni napor kako bi prevazišle prepreke, lako
      se demotivišu, a motivatori su im najčešće spoljašnji i kratkoročni. Greške ih frustriraju, kao i kritike 
      drugih. Drugi ih često vide kao manje pouzdane, nedovoljno aktivne i nestabilne. Pod pritiskom se
      uznemire i često menjaju strategiju. Teže preuzimaju odgovornost za svoje postupke. 
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Preterano niski skorovi često ukazuju na negativnu samoprocenu osobe, najčešće kao posledicu
      doživljavanja sopstvene nekompetentnosti, pa i depresivnog kognitivnog stila.
    `,
    },
  },
  PRO: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ekstremno visoki skorovi često ukazuju na osobe previše sklone riziku. Isto tako, one mogu lako
      preceniti svoje kapacitete i na osnovu toga doneti pogrešne procene i preterano se izložiti različitim
      ugrožavajućim situacijama.
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da su vrlo proaktivne. Imaju kapacitet za preuzimanje vođstva i ulaganje napora
      kako bi napravile funkcionalne promene u svojoj sredini. Otvorene su za novine. U poslu biraju teže
      zadatke i usvajaju efikasnije tehnike prevladavanja problema. Vešti su pregovarači, imaju visoko
      samopouzdanje, lakše se nose sa frustracijama i stresom. Negativni efekti posla (stresori, ograničenja,
      rad pod pritiskom i sl.) kod ovih osoba podstiču proaktivno ponašanje. 
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ove osobe su umereno proaktivne. Dobro će se snalaziti u novim situacijama i trudiće se da unesu
      modifikacije i promene tamo gde ocene da je potrebno. Iniciraće one aktivnosti za koje imaju relativno
      jasnu ideju o tome kakav može biti ishod. Preuzimaće vođstvo samo u određenim situacijama i to će
      raditi sasvim dobro. Otvorene su za učenje. Vole novine, ali umeju da razumeju ograničenja koje
      nameću date okolnosti. Poseduju načine da kompenzuju stres, ali ih on retko dodatno motiviše na
      akciju.
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Ove osobe imaju skromnije mišljenje o sebi i svojim sposobnostima, ali će kreirati i predlagati
      određene promene kako u privatnoj tako i u poslovnoj sferi. Podložnije su stresu nego osobe iz viših
      kategorija. 
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da su neproaktivne. Lakše slede druge nego što će same preuzeti inicijativu.
      Konzervativnije su i manje otvorene ka novinama. Lako se obeshrabre i slabije rade pod pritiskom.
      Nesigurne su i lako odustaju. Nemaju potrebu da menjaju okolnosti, čak i kad im one ne odgovaraju.
      Često su nezadovoljne, a krivce za svoju lošu situaciju traže u drugima.
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Ekstremno niski skorovi često pokazuju da je osoba veoma pasivna i nezainteresovana da aktivno
      menja bilo šta u svom okruženju.
    `,
    },
  },
  ODG: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ekstremno visoki skor imaju osobe koje mogu delovati neemocionalno i neosetljivo. Često se utapaju
      u monotoniju. Imaju precenjenu sliku o tradicionalnim vrednostima, često bez realnih potkrepljenja
      za takvo mišljenje.
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da su vrlo odgovorne Poštuju formalnosti i rokove. Imaju dobru samokontrolu,
      dobro su organizovane, ne opstruiraju rad drugih. Rade po predviđenom planu, čak iako im to trenutno
      ne odgovara. Nisu previše kreativni u profesionalnoj sferi. Pokazuju više brige za strategiju, plan i
      poštovanje pravila pa makar to bilo i po cenu efikasnosti. Drugi ljudi ih vide kao nekog na koga mogu
      da se oslone i kome mogu da veruju. Dosledne su I bolje se nose sa stresnim situacijama. Ne vole
      preterana uzbuđenja I, ako mogu da biraju, izabraće strukturisane i predvidljive situacije, u kojima
      imaju potpunu kontrolu.    
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ove osobe smatramo odgovornim osobama, iako ne moraju uvek slepo poštovati pravila i formalnosti.
      Ovo su ljudi koji znaju šta su njihove obaveze i dužnosti. Fleksibilnije su u pravljenu plana i rasporeda
      obaveza. Uglavnom dobro rade, ali nisu perfekcionisti. Imaju svest o tome da drugi ljudi mogu trpeti
      zbog njihovih propusta. Umeju da prave dugoročne planove. Mogu odstupiti od pravila ukoliko
      procene da njihova aktivnost ne daje određene rezultate u određenom vremenu. U retkim situacijama
      mogu pokazati kontraproduktivno ponašanje, kao što je kašnjenje i sl.
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Ove osobe smatramo umereno odgovornim. Ne vole preterano ustaljen red i plan, ali ako ga naprave
      uglavnom će ga se pridržavati. Strategiju prave kada je neophodna, ali su otvorenije za izmene. Nemaju
      preteranu potrebu za kontrolom situacija. Dešava im se da kasne ili da ne poštuju neko od zadatih
      pravila, ali to uglavnom ne ugrožava sam proces rada. Kada imaju jasnu sliku svog cilja ili njegovog
      benefita, mogu se lako dovesti u red i ispoljiti karakteristike odgovorne osobe. 
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da su neodgovorne. Ne vole disciplinu i red. Lošije su organizovane i više
      vole da improvizuju, pokazuju površnost u vezi sa ciljevima, lako odustaju. Mogu se pokazati kao manje
      obzirne prema drugima. Ne podnose svakodnevnu rutinu. Drugi ih vide kao nekog ko je vrlo nepouzdan
      i na koga se ne treba oslanjati. Karakteriše ih odsustvo samodiscipline, te često kasne i ne dovršavaju
      svoje obaveze. 
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Ove osobe ne pokazuju brigu ni prema sebi ni prema drugima. Čak iako imaju dobre kognitivne
      predispozicije, često ne dovršavaju školovanje, niti pokazuju rezultate u tome što rade. Žive stihijski,
      od danas do sutra. Motiviše ih kratkoročno zadovoljstvo. Često menjaju poslove, jer imaju problem da
      se bilo gde zadrže.
    `,
    },
  },
  INO: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ekstremno visok skor često ukazuje na osobe koje su prepune ideja bez realnih mogućnosti za njihovo
      ostvarenje, bez plana i praktične implikacije i realizacije istih.
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da su veoma inovativne. Obično idu izvan okvira svog znanja tražeći nešto novo,
      drugačije i više, retko prihvataju konvencionalni način razmišljanja. Probleme rešavaju uporno i na
      drugačije i originalnije načine. Takođe, pokazuju sklonost ka eksperimentisanju koje može rezultirati
      novim proizvodima, uslugama ili poboljšanim organizacionim procesima ili procedurama. Obično su
      kooperativne, dobre naravi i fleksibilne, ali ih tolerancija i slaganje sa drugima mogu ometati u radu.
      Ponekad može da ih ponese lična ambicija, ali im to nije primarni pokretač.
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ove osobe možemo posmatrati kao uglavnom inovativne. Vole novine i poseduju nešto manje
      konvencionalan način razmišljanja. Problemima pristupaju originalnije, a posebno ako je uobičajen
      način dao loše rezultate. Vole da uče novo. Biraće situacije u kojima procene da se rizik isplati.
      Fleksibilniji su u radu. Pozitivni su i umeju da prepoznaju vrednost drugih. Otvorene su za tuđu
      inovativnost.    
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Srednje nizak skor govori o nešto skromnijoj inovativnosti. Ove osobe će pre biti otvorene za tuđu
      inovativnost i nove ideje, koje će prepoznati i ceniti jer im se nameću pitanja koja nemaju očigledno
      rešenje, nego što će blistati sopstvenim idejama. Kako su bliže 20. percentilu, i te naznake otvorenosti
      i skromne povremene originalnosti se gube.    
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da nisu inovativne. Rešenja su im često predvidljiva i opšteprihvaćena. Ne
      idu preko svojih granica i više vole definisane zadatke i predvidive ishode. 
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Ekstremno nizak skor ukazuje na nemaštovite i neoriginalne osobe koje najčešće nikad ništa ne
      preispituju. Prihvataju opšteprihvaćena pravila, obično u svim životnim sferama. Ne pokazuju
      inicijativu da nauče bilo šta novo. Više im odgovara rutina.
    `,
    },
  },
  EFI: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ekstremno visok skor ukazuje na osobe koje mogu preceniti sopstvene sposobnosti i ponašati se vrlo
      nadmeno, bez realnog utemeljenja.
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da su veoma efikasne, i to najčešće u svim sferama života. Vole intenzivno da
      rade, zadatke obavljaju kvalitetno i postavljaju visoke ciljeve (obično dugoročne). Često su vrlo
      nezavisne u razmišljanju i akcijama i snalažljive u rešavanju problema. Najbolje se pokazuju u
      situacijama koje traže reagovanje i restrukturisanje. Prija im dinamično okruženje. Nisu preterano
      osetljive na kritiku, a eventualni neuspeh će ih motivisati da se trude još više. Prilično su otporne na
      stres. Otvorene su za lični I profesionalni razvoj, lako prihvataju promene i novu tehnologiju.
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ove osobe su uglavnom efikasne. Postavljaju sebi ciljeve koje ostvaruju. Uče iz iskustva, snalaze se u
      rešavanju problema i često su nezavisne u razmišljanju. Neuspeh umeju da kompenzuju. Često znaju
      da rade na sebi. Uglavnom su otvorene i komunikativne I nisu preterano osetljive na kritiku. Često
      prave plan i strategiju rada. Mogu da ulože dodatni napor u ostvarivanju svojih ciljeva
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Ove osobe pokazuju skromniju efikasnost. Mogu imati dugoročne ciljeve, ali ne preterano jer znaju da
      se zadovolje mnogo manjim. Prethodni neuspeh može da ih obeshrabri. Dobro se osećaju u poznatim
      aktivnostima, te će tu pokazati crte efikasnosti. Radiće na sebi ukoliko to smatraju svrsishodnim i neće
      gubiti vreme na bilo šta samo da bi se usavršavale. Mogu biti osetljive na tuđu kritiku, ali ne uvek. Plan
      i strategiju prave kada je to neophodno, te tu mogu ispoljiti pravovremeno reagovanje i istrajnost.
      Ljudi ih češće smatraju nezainteresovanim za određene oblasti ili situacije nego neefikasnim, što
      zapravo I jesu.
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da su neefikasne. Teže uče iz iskustva, bilo svog ili tuđeg. Nemaju previše
      poverenja u sebe i svoje sposobnosti, te izbegavaju izazovne zadatke. Osetljive su na kritiku. Sklone su 
      da se fokusiraju na lične nedostatke i negativne ishode i tako ograničavaju svoj potencijal za učenje i
      razvoj. Često imaju negativnu anticipaciju ishoda različitih događaja, a za svoj neuspeh će često okriviti
      druge ljude ili situacije.
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Ekstremno niski skorovi ukazuju na osobe koje u susretu sa zahtevima često reaguju uznemirenošću,
      a nije redak ni depresivan odgovor. Uglavnom odustaju od svojih ciljeva. Za sopstveni neuspeh će često
      okriviti druge ljude ili situacije.
    `,
    },
  },
  VK: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ekstremno visoki skorovi ukazuju na osobe koje mogu biti nesmotreno i neutemeljeno optimistične,
      naporne i preplavljujuće za prosečnog sagovornika.
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da poseduju odlične komunikacione veštine. Često preuzimaju inicijativu u
      razgovoru, fleksibilne su i umeju da se prilagode sagovorniku. Jasno se i precizno izražavaju. Često
      proveravaju da li ih je sagovornik razumeo I obično postižu svoj cilj kroz razgovor. Dobro kontrolišu
      emocije I prepoznaju tuđe. Tolerantne su i lakše prihvataju različitosti. Odgovara im rad u timu.
      Otpornije su na stres, međutim, mogu da budu osetljive na socijalni pritisak i kritiku. Veruju u svoje
      veštine u socijalnim interakcijama u profesionalnoj sredini i poseduju kapacitet za inicijaciju,
      održavanje i razvoj odnosa sa drugima. 
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ove osobe uglavnom smatramo ,,komunikativnim’’ osobama i dobrim sagovornicima. Nemaju
      problema u komunikaciji sa nepoznatim ljudima i dobro se prilagođavaju sagovorniku. Jasne su u
      izražavanju i potrudiće se da ih sagovornik razume. Uglavnom dobro kontrolišu emociju u toku
      razgovora i mogu prepoznati tuđe. Vole razmenu ideja i razmišljanja sa drugim ljudima i uglavnom
      uživaju u komunikaciji.
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Ove osobe posmatramo kao prosečne sagovornike. Oni više vole poznate sagovornike nego
      nepoznate, mada se mogu snaći kada treba i u nepoznatoj socijalnoj situaciji. Mogu se pokazati kao
      vrlo komunikativni sa sagovornicima koji im odgovaraju. Međutim, ako im sagovornici ne prijaju, iz
      bilo kog razloga, mogu se povući iz komunikacije ili u nju biti vrlo površno uključeni. Shodno iskustvu
      koje sagovornici imaju sa njima, posmatraće ih ili kao prijatne ili kao nezainteresovane. 
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da poseduju loše komunikacione veštine. One ređe preuzimaju inicijativu
      u razgovoru, pogotovo u društvu nepoznatih ljudi. Tokom razgovora najčešće su usmerene na sebe i 
      na to kako se one osećaju, te sagovornika stavljaju u drugi plan. Ne vole da daju previše informacija o
      sebi i cene svoju privatnost nauštrb povezivanja sa sagovornikom. Mogu biti nejasne u svom izlaganju,
      previše opširne ili previše šture. Razgovorom retko postižu svoj cilj.
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Ekstremno nizak skor ukazuje na osobe koje su uglavnom zatvorene za komunikaciju. U razgovoru sa
      ljudima koje dobro ne poznaju obično su uznemirene i primetno im je nelagodno. Često mogu unositi
      tenziju u razgovor.
    `,
    },
  },
  SAR: {
    95: {
      to: `100`,
      title: `Ekstremno visok skor`,
      text: `
      Ekstremno visok skor ukazuje na osobe koje mogu da izgube svoje granice i da se potpuno utope u
      tim. Previše slede druge i često zaborave vlastite potrebe i ciljeve. Mogu da budu previše popustljive,
      pa i na svoju štetu.
    `,
    },
    80: {
      to: `95`,
      title: `Visok skor`,
      text: `
      Za ove osobe kažemo da su vrlo saradljive. One su obično tolerantne i saosećajne, sklone da uvažavaju
      saradnike i budu ,,fer’’ i principijelne u interpersonalnim odnosima. Veoma im znači podrška drugih i
      razmena mišljenja. Zajednički cilj im je obično ispred ličnog. U timu umeju da prate, ali i da preuzmu
      inicijativu ako je to za opšte dobro. Lako prihvataju novine. Često se rukovode etičkim principima.
      Aktivno prate konverzaciju i dobro kumuniciraju. Izbegavaju konflikte kadgod mogu, a ako do njih
      dođe, uvek traže dodatne informacije i kompromisno rešenje. Neretko odlučuju vodeći se emocijama. 
    `,
    },
    50: {
      to: `80`,
      title: `Srednje visok skor`,
      text: `
      Ove osobe smatramo uglavnom dobro saradljivim. U suštini to su tolerantne osobe koje prihvataju
      različita mišljenja. Često deluju srdačno i nude pomoć. Razmenjuju mišljenja sa drugima i cene dobre
      međuljudske odnose. Često umeju da pokažu svoja osećanja i uglavnom su iskrene. Retko će svoj cilj
      staviti ispred grupnog. Prihvataju novine i uglavnom su pristupačne. Poštuju etička načela, sa ponekim
      izuzetkom. Saosećaju sa drugima i često će pružiti podršku.
    `,
    },
    20: {
      to: `50`,
      title: `Srednje nizak skor`,
      text: `
      Sa ovim osobama se solidno sarađuje. Svakako da imaju nekih manjkavosti u samom odnosu sa
      drugima (na primer predrasude), međutim, to može da ukazuje i na preferencu individualnog rada ili,
      na primer, introverniju strukturu ličnosti koja voli samostalan rad (ne nužno na ,,samotnjake’’). Kako
      se približava donjoj granici, vrednosti saradljive osobe se dosta gube.
    `,
    },
    5: {
      to: `20`,
      title: `Nizak skor`,
      text: `
      Za ove osobe možemo reći da su nesaradljive, odnosno ,,teške za saradnju’’. Često su netolerantne i
      kritične. Primarno su fokusirane na svoje potrebe, skeptične u pogledu namera drugih ljudi i često
      antagonistički i takmičarski nastrojene.. Obično su oportunisti. Retko pružaju podršku, obično zato što
      ne prepoznaju kada treba da je pruže. Odgovara im samostalan rad. Ako rade u timu, važno im je
      ostvarenje ličnih ciljeva na putu do zajedničkog.
    `,
    },
    0: {
      to: `5`,
      title: `Ekstremno nizak skor`,
      text: `
      Ekstremno niski skorovi su tipični za ljude koji vole samoću, ali i imaju problema u socijalnim
      relacijama. Grupni rad ih često čini anksioznim i netrpeljivim. Teško praštaju i neretko su osvetoljubivi.
      Nemaju toleranciju za različitosti kod ljudi i mogu biti puni predrasuda.
    `,
    },
  },
};

export const templateReport = {
  promisljenost: {
    85: {
      to: `100`,
      title: `Visok skor`,
      text: `
      Ove osobe uglavnom donose odluke vagajući šta je ispravno u etičkom smislu, svesne su uticaja
      posledica svojih odluka na druge, spremnije su da se suoče sa rizikom gubitka u slučaju pogrešne
      odluke i u procesu donošenja odluke često prikupljaju sve relevantne informacije. Obično su upornije
      i znatno aktivnije u nastojanjima da reše probleme. 
    `,
    },
    15: {
      to: `85`,
      title: `Srednji skor`,
      text: `
      Ove osobe poseduju selektivan odnos prema etičkim načelima, nisu uvek spremne da se suoče sa
      verovatnoćom gubitka u slučaju pogrešne odluke. Potreban im je optimalan nivo relevantnih
      informacija. Imaju svest o posledicama svojih odluka na druge, ali neće po svaku cenu poštovati to
      načelo, već će prihvatiti koncept “kolateralne” štete ako do toga dođe kao posledica njihovih odluka.
    `,
    },
    0: {
      to: `15`,
      title: `Nizak skor`,
      text: `
      Ove osobe, pri donošenju odluka, često krše osnovna etička merila, nemaju razvijenu svest o
      posledicama svojih odluka na druge ljude, ne vole i izbegavaju neizvesnost ishoda i nisu sklone
      skupljanju relevantnih informacija kada donose odluke. Imaju manje elana prilikom odlučivanja i u
      manjoj meri ispoljavaju tendenciju povezivanja sa drugima. Teško se snalaze u nepoznatim socijalnim
      situacijama.
    `,
    },
  },
  pragmaticnost: {
    80: {
      to: `100`,
      title: `Visok skor`,
      text: `
      Ove osobe uglavnom odluke donose samostalno, lako i “hladne glave”, vodeći se praktičnim
      implikacijama, pristaju na zadovoljavajuće rešenje, koje nužno ne mora biti i najbolje rešenje.
      Najčešće posmatraju stvari samo iz sopstvene perspektive i brzo menjaju pravila kako bi opravdale
      svoje postupke. Uglavnom ne vole dvosmislene i nesigurne situacije, teže uspostavljaju kontrolu,
      manje su tolerantne i saosećajne prema drugima. 
    `,
    },
    10: {
      to: `80`,
      title: `Srednji skor`,
      text: `
      Osobe sa srednjim skorovima, često pristaju na optimalna rešenja, koja nisu uvek najpraktičnija. Nisu
      uvek samostalne u donošenju odluka. Ne donose odluke uvek hladnom glavom, već shodno određenoj
      situaciji. Njihovo emocionalno stanje može imati uticaj na proces donošenja odluka.
    `,
    },
    0: {
      to: `10`,
      title: `Nizak skor`,
      text: `
      Osobe sa niskim skorom na ovoj dimenziji, u procesu odlučivanja češće biraju utopijska naspram
      praktičnih rešenja i trude se da postignu maksimum, čak i kada ga je teško dostići, što može uticati na
      smanjenje efikasnosti. Nesamostalne su, teže im pada sam proces odlučivanja i njihova emocionalna
      stanja znatno mogu uticati na sam tok donošenja odluke. Sa druge strane, one su saradljivije i
      tolerantnije, te im mišljenje, podrška i perspektiva drugih ljudi dosta znače u odlučivanju.
    `,
    },
  },
  racionalnost: {
    80: {
      to: `100`,
      title: `Visok skor`,
      text: `
      Ove osobe odluke donose brzo i one su najčešće utemeljene na jakim i konkretnim činjenicama. Imaju
      razumne i objektivne razloge za svoje odluke, pa su samim tim one i objektivnije. Sigurne su u svoje
      odluke i ispravnost svog načina razmišljanja, ali im je važna i potvrda i podrška drugih u odlučivanju.
      *Osobe sa izuzetno visokim skorom mogu delovati neosetljivo I hladno
    `,
    },
    15: {
      to: `80`,
      title: `Srednji skor`,
      text: `
      Osobe sa srednjim skorovima, odluke donose umerenom brzinom, zavisno od toga koliko poseduju
      realnu sliku o određenoj situaciji. Nemaju uvek pravu sliku pa mogu biti nešto manje objektivne u
      proceni i nisu uvek sigurne u ispravnost svojih odluka. Zbog toga mogu biti podložne socijalnom
      pritisku.
    `,
    },
    0: {
      to: `15`,
      title: `Nizak skor`,
      text: `
      Ove osobe odluke donose intuitivno, vođenje unutrašnjim ,,glasom’’ i potrebno im je mnogo više
      vremena. Stidljivije su, neasertivne i nedostaje im samopouzdanja u komunikaciji. Retko se izbore za
      ono što žele. Često im nedostaje cilj i usmerenje u njihovim aktivnostima. U nepoznatim situacijama
      se ne osećaju dobro.
      *Izuzetno nizak skor može ukazati na plašljive, pasivne i pesimistične osobe, koja stalno sumnjaju u
      sebe I svoje odluke
    `,
    },
  },
  sistematicnost: {
    80: {
      to: `100`,
      title: `Visok skor`,
      text: `
      Ove osobe svrsishodno koriste svoja, ali i tuđa iskustva u donošenju odluka i svoje odluke pažljivo
      planiraju. Trude se da objektivno percipiraju situacije, ali im može nedostajati originalnosti u
      razmišljanju pri odlučivanju. Značajna im je, mada ne i neophodna, socijalna validacija njihovih odluka.
      Osetljivije su na odbijanje i uvrede. U profesionalnoj sferi nedostaje im inovativnosti i do novih ideja
      dolaze uglavnom preko drugih ljudi. 
    `,
    },
    15: {
      to: `80`,
      title: `Srednji skor`,
      text: `
      Ove osobe selektivno koriste kako svoja tako i tuđa iskustva u donošenju odluka, shodno datoj situaciji.
      Neke odluke planiraju, neke ne. Prija im da ponekad zauzmu potpuno drugačiji pristup. Mogu da
      improvizuju u datim okolnostima.
    `,
    },
    0: {
      to: `15`,
      title: `Nizak skor`,
      text: `
      Ove osobe odluke donose zauzimajući potpuno nov i drugačiji pristup od onog koji bi inače zauzele.
      Često odlučuju stihijski i nestrategijski, čak i impulsivno. Ne vole rutinu i pravila i teže ka inovativnim
      odlukama.
    `,
    },
  },
  preduzimljivost: {
    80: {
      to: `100`,
      title: `Visok skor`,
      text: `
      Ove osobe vole da donose odluke i nameću svoje mišljenje. Nisu previše originalne i odluke donose
      relativno brzo. Nemaju strah od neizvesnih situacija ni ishoda. Obično su izuzetno uporne I efikasne.
      Dobro su organizovane i preuzimaju odgovornost za svoje postupke.
    `,
    },
    15: {
      to: `80`,
      title: `Srednji skor`,
      text: `
      Ove osobe donose odluke kada je to potrebno i nemaju otpor prema tome. Odluke donose umerenom
      brzinom, obično zato što ocenjuju argumente za i protiv. Solidno kontrolišu svoje emocije i
      samopouzdanje im je prosečno.
    `,
    },
    0: {
      to: `15`,
      title: `Nizak skor`,
      text: `
      Ove osobe ne vole da donose odluke i to im predstavlja određen vid stresa (često postaju uznemirene
      kad su primorane da nešto odluče). Nemaju sklonost da rizikuju. Povučenije su i imaju manje
      samopouzdanja. Lako odustaju, teže kontrolišu ljutnju, a i ostale emocije. Odgovaraju im spontane
      odluke koje se prosto same nametnu.
    `,
    },
  },
  analiticnost: {
    85: {
      to: `100`,
      title: `Visok skor`,
      text: `
      Ovo su osobe koje vole mogućnost izbora i sklone su da istražuju činjenice, sagledavaju i procenjuju
      više alternativa i metodom eliminacije odaberu onu za koju misle da je najbolja. Otvorene su prema
      ljudima I uzimaju u obzir tuđe mišljenje. Prija im potvrda drugih i imaju potrebu za socijalnom
      podrškom. Osetljive su na kritiku i odbijanje.
    `,
    },
    15: {
      to: `85`,
      title: `Srednji skor`,
      text: `
      Ove osobe vole mogućnost izbora, ali neće preterivati u analiziranju svake činjenice. Takođe skloni su
      da prihvate optimalno rešenje za datu situaciju
    `,
    },
    0: {
      to: `15`,
      title: `Nizak skor`,
      text: `
      Niske skorove na ovoj dimenziji imaju uglavnom osobe koje brzo vide pravo rešenje. Sigurnije su u
      sebe, pa samim tim i u svoj izbor. Nije im neophodna podrška i prihvatanje drugih. 
    `,
    },
  },
};

export const criteriaReport = {
  brzina: {
    85: `brzo dobnosi odluke`,
    10: `donosi odluke umerenom brzinom`,
    0: `odluke donosi sporo`,
  },
  preuzimanje: {
    85: `preuzima odgovornost za svoje odluke`,
    20: `delimično preuzima odgovornost za svoje odluke`,
    0: `ne preuzima odgovornost za svoje odluke`,
  },
  samostalnost: {
    85: `samostalno donosi odluke`,
    10: `umereno samostalno donosi odluke`,
    0: `ne donosi odluke samostalno`,
  },
  informisanost: {
    85: `potrebno mu je puno relevantih informacija da bi doneo odluku`,
    10: `potreban mu je optimalan broj informacija da bi doneo odluku`,
    0: `potrebno mu je malo relevantnih informacija da bi doneo odluku`,
  },
  prakticnost: {
    80: `prilikom donošenja odluka traži praktična rešenja`,
    15: `prilikom donošenja oluka ne traži uvek praktična rešenja`,
    0: `prilikom donošenja odluka teži svom ličnom idealu`,
  },
  objektivnost: {
    80: `teži objektivnosti, odnosno prilikom odlučivanja uzima u obzir činjenice koje su ispravne, nepristrasne, realne i utemeljene na stvarnom stanju stvari`,
    10: `umereno teži objektivnosti, odnosno prilikom odlučivanja uzima u obzir činjenice koje nisu uvek nepristrasne i mogu odstupati od realnosti`,
    0: `ne teži objektivnosti, odnosno prilikom odlučivanja je pristrasan, a činjenice često ne odgovaraju pravom stanju stvari`,
  },
  eticnost: {
    80: `u odlučivanju se vodi etičkim načelima koja za njega predstavljaju standard za procenu koje odluke su dobre, a koje loše`,
    10: `– ima relativan odnos prema etičkim načelima pa ih tako i selektivno primenjuje prilikom odlučivanja`,
    0: `ne vodi se etičkim načelima u procesu odlučivanja`,
  },
  rizik: {
    80: `preuzima rizik za ishode svojih odluka`,
    15: `umereno preuzima rizik za ishode svojih odluka`,
    0: `ne preuzima rizik za ishode svojih odluka`,
  },
  originalnost: {
    85: `razmišlja nezavisno i kreativno u samom procesu odlučivanja i shodno tome nudi jedinstvena i autentična rešenja`,
    10: `ne razmišlja uvek kreativno i nezavisno u samom procesu odlučivanja, ponekad nudi originalna rešenja`,
    0: `ne nudi originalna rešenja kao ishod svojih odluka`,
  },
  dvoumljenje: {
    85: `sagledava i dvoumi se između alternativa kada dodosi odluke`,
    15: `ponekad se dvoumi kada donosi odluke`,
    0: `ne dvoumi se kada donosi odluke`,
  },
  tezina: {
    85: `lako donosi odluke`,
    15: `pojedine odluke donosi lako, ali određene odluke mogu predstavljati problem`,
    0: `teško donosi odluke`,
  },
  voljnost: {
    90: `voli da odlučuje`,
    15: `odlučuje kada je potrebno`,
    0: `ne voli da odlučuje`,
  },
  socijalna: {
    85: `traži socijalnu validaciju svojih odluka i podršku`,
    10: `ponekad traži socijalnu validaciju svojih odluka ili podršku`,
    0: `ne traži traži socijalnu validaciju svojih odluka, niti podršku`,
  },
  emocionalnost: {
    90: `emocije znatno utiču na proces donošenja odluka`,
    20: `određena emocionalna stanja mogu uticati na proces donošenja odluka`,
    0: `odluke donosi “hladne glave” odnosno emocionalna stanja nemaju uticaj na proces donošenja odluka`,
  },
  kvalitet: {
    90: `traži zadovoljavajuće rešenje`,
    15: `traži optimalno rešenje`,
    0: `traži najbolje moguće rešenje`,
  },
  racionalnost: {
    85: `racionalno donosi odluke odnosno ima tendenciju ka jasnom i razumnom razmišljanju koje se bazira na utemeljenim razlozima u procesu donošenja odluke`,
    10: `prilikom donošenja odluka koristi i racio i intuiciju shodno situaciji`,
    0: `odluke donosi intuitivno i često mogu biti iracionalne`,
  },
  planiranje: {
    80: `odluke planira, definiše korake i ciljeve`,
    10: `ponekad planira odluke`,
    0: `odluke donosi impulsivno i stihijski`,
  },
  koriscenje: {
    85: `prilikom odlučivanja koristi svoje i tuđe prethodno iskustvo u sličnim situacijama kako bi se predvideli mogući ishodi`,
    10: `prilikom odlučivanja ponekad i umereno koristi svoje i tuđe prethodno iskustvo u sličnim situacijama kako bi se predvideli mogući ishodi`,
    0: `prilikom odlučivanja ne planira već zauzima potpuno nov pristup i zanemarujući prethodna iskustva`,
  },
};
