












































import API from '@/plugins/axios';
import Vue from 'vue';
import CampaignList from './CampaignList.vue';
export default Vue.extend({
  components: { CampaignList },
  name: 'AdminHome',
  data: () => ({
    psych: {
      email: '',
      forename: '',
      surname: '',
      contact_phone: '',
      title: '',
    },
  }),
  computed: {},
  methods: {},
  async mounted() {
    const { data, status } = await API.get('/psych/self/');
    if (status === 200) {
      this.psych = { ...data };
    }
  },
});
