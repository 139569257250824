





















import { BASE_URL, setupAxiosToken } from '@/plugins/axios';
import { VForm } from '@/plugins/vuetify';
import Axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  name: 'Login',
  data: () => ({
    form: {
      email: '',
      password: '',
    },
    invalidUser: {
      email: undefined as undefined | string,
      password: undefined as undefined | string,
    },
    rules: {},
    validForm: false,
    gotToken: false,
  }),
  methods: {
    async login() {
      if (!(this.$refs.form as VForm).validate()) return;
      try {
        const { data } = await Axios.post(`${BASE_URL}/auth/login/`, {
          email: this.form.email,
          password: this.form.password,
        });
        const { access, refresh, ...userData } = data;
        localStorage.setItem('access', access);
        localStorage.setItem('refresh', refresh);
        setupAxiosToken();
        await this.$store.dispatch('setUserData', { ...userData, email: this.form.email });
        this.$router.push('/');
      } catch (e) {
        if (e.response?.status === 401) {
          this.invalidUser.email = this.form.email;
          this.invalidUser.password = this.form.password;
          if (!(this.$refs.form as VForm).validate()) return;
        }
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!v.length || error;
    },
  },
  mounted() {
    this.rules = {
      email: [
        this.isPopulated('Email mora biti popunjen'),
        (v: string) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Email mora biti validan',
      ],
      password: [
        this.isPopulated('Lozinka mora biti popunjena'),
        (v: string) => /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(v) || 'Lozinka mora da ima slova i brojeve',
        (v: string) =>
          this.invalidUser.email !== this.form.email ||
          this.invalidUser.password !== v ||
          'Email ili lozinka su pogrešni',
      ],
    };
    this.gotToken = !!localStorage.getItem('campaign_uuid');
  },
});
