





















import { BASE_URL } from '@/plugins/axios';
import Axios from 'axios';
import Vue from 'vue';
export default Vue.extend({
  name: 'ForgotPassword',
  data: () => ({
    form: {
      email: '',
    },
    rules: {},
    validForm: false,
    formSent: false,
  }),
  methods: {
    async forgotPassword() {
      try {
        await Axios.post(`${BASE_URL}/auth/reset_password_request/`, {
          email: this.form.email,
        });
        this.formSent = true;
      } catch (e) {
        console.error(e);
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!v.length || error;
    },
  },
  mounted() {
    this.rules = {
      email: [
        this.isPopulated('Email must be populated'),
        (v: string) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Email must be valid',
      ],
    };
  },
});
