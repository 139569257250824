
import Vue from 'vue';
import { Bar, mixins } from 'vue-chartjs';

export default Vue.extend<unknown, Bar, unknown, 'chartData' | 'options'>({
  extends: Bar,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
});
