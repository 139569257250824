























import Vue from 'vue';
import CampaignList from './CampaignList.vue';
import CompanyList from './CompanyList.vue';
export default Vue.extend({
  components: { CompanyList, CampaignList },
  name: 'AdminHome',
  data: () => ({
    tab: 'Kompanije',
  }),
  computed: {},
  methods: {},
});
