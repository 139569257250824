






















import API from '@/plugins/axios';
import Vue from 'vue';
import dayjs from 'dayjs';

export default Vue.extend({
  name: 'TestIntro',
  data: () => ({
    applications: [],
    isCompany: false,
  }),
  methods: {
    async createQuestionnaire() {
      try {
        const { data } = await API.post('/candidate/questioners/');
        if (data) {
          await this.$store.dispatch('setQuestions', data);
          await this.$router.push(`/test/${this.$route.params.application_id}/${data.id}`);
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  async mounted() {
    const { data: self } = await API.get('/candidate/self/');
    if (self) {
      this.applications = self.applications;
      const app = self.applications.find((a: { id: string }) => a.id == this.$route.params.application_id);
      if (app) {
        this.isCompany = app.campaign.campaign_type == 'company_profile';
      }
    }
    const { data } = await API.get('candidate/questioners/');
    await this.$store.dispatch('setQuestioners', data);
    if (data.length && !this.isCompany) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const qIds = this.applications.reduce((prev, next: any) => {
        if (next.campaign.campaign_type == 'company_profile') {
          prev.push(next.questioner_id);
        }
        return prev;
      }, [] as number[]);
      const notCompanyQuestionnaires = data.filter((q: { id: number }) => !qIds.includes(q.id));
      if (
        notCompanyQuestionnaires.length &&
        (notCompanyQuestionnaires.some((q: { is_finished: boolean }) => !q.is_finished) ||
          (notCompanyQuestionnaires[0].is_finished &&
            dayjs(notCompanyQuestionnaires[0].finished_at) >= dayjs().subtract(6, 'month')))
      ) {
        await this.$router.push('/');
      }
    }
  },
});
