



































import Vue from 'vue';
export default Vue.extend({
  name: 'FileDrop',
  data: () => ({
    dragover: false,
    files: [] as unknown[],
    error: '',
    maxFileSize: 1024,
  }),
  props: ['logo'],
  methods: {
    drop(e: DragEvent) {
      // eslint-disable-next-line no-debugger
      if (e.dataTransfer) {
        this.dragover = false;
        this.files = [...Array.from(e.dataTransfer.files)];
        const filesize = (e.dataTransfer.files[0].size / 1024).toFixed(4); // MB
        if (Number(filesize) > this.maxFileSize) {
          this.error = 'Logo mora biti manji od 1MB';
        } else {
          this.$emit('filesSelected', Array.from(e.dataTransfer.files));
          this.error = '';
        }
      }
    },
    onChange(e: InputEvent) {
      const target = e.target as HTMLInputElement;
      if (target.files) {
        this.files = [...Array.from(target.files)];
        const filesize = (target.files[0].size / 1024).toFixed(4); // MB
        if (Number(filesize) > this.maxFileSize) {
          this.error = 'Logo mora biti manji od 1MB';
        } else {
          this.$emit('filesSelected', Array.from(target.files));
          this.error = '';
        }
      } else {
        this.removeFiles();
      }
    },
    removeFiles() {
      this.files = [];
      (this.$refs.input as HTMLInputElement).value = '';
      this.$emit('filesSelected', []);
      this.error = '';
    },
  },
});
