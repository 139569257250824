
import Vue from 'vue';
import { Line, mixins } from 'vue-chartjs';

export default Vue.extend<unknown, Line, unknown, 'chartData' | 'options'>({
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
});
