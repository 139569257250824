






































































































































































































































































































































































































































































































































































import API from '@/plugins/axios';
import Vue from 'vue';
import draggable from 'vuedraggable';
import BarChart from '@/components/BarChart.vue';
import LineChart from '@/components/LineChart.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { characteristics, template, criteria, vocationalQualifications, testType } from '@/utils/data';
dayjs.extend(relativeTime);
dayjs.locale('sr');
export default Vue.extend({
  name: 'PsychCampaign',
  components: {
    draggable,
    BarChart,
    LineChart,
  },
  data: () => ({
    filtersExpanded: true,
    tab: 0,
    items: [
      { text: 'Osobine', value: 'personality' },
      { text: 'Obrazac', value: 'dimensions' },
      { text: 'Pojedinačni kriterijumi', value: 'decisions' },
    ],
    characteristics: [...characteristics],
    template: [...template],
    criteria: [...criteria],
    campaign: [] as unknown[],
    testType: [...testType],
    candidateFilters: {
      gender: {
        male: true,
        female: true,
      },
      education_level: [] as string[],
      birth_year: {
        from: '',
        to: '',
      },
      total_seconds: [0, 360],
    },
    excludeTime: false,
    drag: false,
    vocational_qualifications: [...vocationalQualifications],
    colorPallete: [
      '#ff0000',
      '#00ff00',
      '#0000ff',
      '#4b0082',
      '#2f4f4f',
      '#a52a2a',
      '#006400',
      '#48d1cc',
      '#ffa500',
      '#ffff00',
      '#00fa9a',
      '#d8bfd8',
      '#ff00ff',
      '#1e90ff',
      '#f0e68c',
      '#ff69b4',
    ],
    peljaPallete: [
      '#1ABC9C',
      '#3498DB',
      '#8E44AD',
      '#F1C40F',
      '#E74C3C',
      '#16A085',
      '#2980B9',
      '#6F869D',
      '#F39C12',
      '#C0392B',
      '#2ECC71',
      '#9B59B6',
      '#2C3E50',
      '#E67E22',
      '#827717',
      '#27AE60',
      '#4A148C',
      '#34495E',
      '#D35400',
      '#795548',
    ],
    selected: [],
    hover: '',
    percent: true,
    barChart: true,
    companyName: '',
    positionName: '',
    characteristsSelect: [0, 1, 2, 3, 4, 5, 6],
    templateSelect: [0, 1, 2, 3, 4, 5],
    criteriaSelect: 0,
    menuOpen: false,
    characteristicsSortOrder: 'DESC',
    templateSortOrder: 'DESC',
    candidatesInReport: [] as number[],
    applicationsOptions: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
    applicationsLength: 0,
    numCandidates: 0,
    numSubmited: 0,
    campaignTestType: [],
    initLoaded: false,
  }),
  computed: {
    reportPage() {
      return `/psych/report/${this.$route.params.id}`;
    },
    orderedCharacteristics() {
      return [...this.characteristics].sort((a, b) => a.order - b.order);
    },
    orderedTemplate() {
      return [...this.template].sort((a, b) => a.order - b.order);
    },
    characteristsSelected() {
      if (this.characteristsSelect.length === this.characteristics.length) {
        return `Sve karakteristike`;
      }
      const selected = [...this.characteristsSelect].sort().map(i => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const char = (this as any).orderedCharacteristics[i];
        return char.name;
      }) as string[];

      const els = selected.slice(0, 2).join(',');

      return selected.length > 2 ? `${els} +${selected.length - 2}` : els;
    },
    templateSelected() {
      if (this.templateSelect.length === this.template.length) {
        return `Sve karakteristike`;
      }
      const selected = [...this.templateSelect].sort().map(i => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const char = (this as any).orderedTemplate[i];
        return char.name;
      }) as string[];

      const els = selected.slice(0, 2).join(',');

      return selected.length > 2 ? `${els} +${selected.length - 2}` : els;
    },
    criteriaSelected() {
      return this.criteria[this.criteriaSelect].name;
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                min: 0,
              },
            },
          ],
        },
      };
    },
    chartData() {
      const labels =
        this.tab === 0
          ? this.characteristics.filter(c => this.characteristsSelect.includes(c.order - 1)).map(c => c.name)
          : this.tab === 1
          ? this.template.filter(t => this.templateSelect.includes(t.order - 1)).map(t => t.name)
          : this.criteria.filter((c, i) => this.criteriaSelect === i).map(c => c.name);
      return {
        labels,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        datasets: [...this.selected].map((selected: any, i) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const user = this.campaign.find((c: any) => c.id === selected.id) as any;
          return {
            label: `${user.candidate.forename} ${user.candidate.surname}`,
            backgroundColor: this.peljaPallete[i % this.peljaPallete.length],
            borderColor: this.peljaPallete[i % this.peljaPallete.length],
            fill: false,
            lineTension: 0,
            minBarLength: 2,
            data: Object.entries(user.questioner[this.items[this.tab].value])
              .filter(([key]) => key.includes('_perc'))
              .filter(([key]) => {
                if (this.tab === 0) {
                  const char = this.characteristics.find(c => c.key === key.slice(0, -5));
                  if (char) {
                    const index = char.order - 1;
                    return this.characteristsSelect.includes(index);
                  }
                  return false;
                } else if (this.tab === 1) {
                  const char = this.template.find(c => c.key === key.slice(0, -5));
                  if (char) {
                    const index = char.order - 1;
                    return this.templateSelect.includes(index);
                  }
                  return false;
                } else if (this.tab === 2) {
                  const index = this.criteria.findIndex(c => c.key === key.slice(0, -5));
                  return this.criteriaSelect === index;
                }
              })
              .reduce((prev, next) => {
                if (this.tab === 0) {
                  const index = this.characteristics.findIndex(c => c.key === next[0].slice(0, -5));
                  prev[index] = next[1];
                } else if (this.tab === 1) {
                  const index = this.template.findIndex(c => c.key === next[0].slice(0, -5));
                  prev[index] = next[1];
                } else {
                  prev.push(next[1]);
                }
                return prev;
              }, [] as unknown[])
              .flat(),
          };
        }),
      };
    },
    tableData() {
      let campaign = [...this.campaign]
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((c: any) => {
          const obj = {} as { [key: string]: string };
          obj.id = c.id;
          obj.name = `${c.candidate.forename} ${c.candidate.surname}`;
          this.characteristics.forEach(char => {
            obj[char.key] = c.questioner[this.items[0].value][char.key];
            obj[`${char.key}_perc`] = c.questioner[this.items[0].value][`${char.key}_perc`];
          });
          this.template.forEach(temp => {
            obj[temp.key] = c.questioner[this.items[1].value][temp.key];
            obj[`${temp.key}_perc`] = c.questioner[this.items[1].value][`${temp.key}_perc`];
          });
          this.criteria.forEach(crit => {
            obj[crit.key] = c.questioner[this.items[2].value][crit.key];
            obj[`${crit.key}_perc`] = c.questioner[this.items[2].value][`${crit.key}_perc`];
          });
          return obj;
        });
      campaign = campaign
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .sort((a: any, b: any) => {
          const sortA = a[this.applicationsOptions.sortBy[0]];
          const sortB = b[this.applicationsOptions.sortBy[0]];

          if (this.applicationsOptions.sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });

      return campaign;
    },
    headers() {
      const headers =
        this.tab === 0
          ? this.characteristics.map(c => ({
              text: c.name,
              value: `${c.key}${this.percent ? '_perc' : ''}`,
              width: `${100 / this.characteristics.length}%`,
            }))
          : this.tab === 1
          ? this.template.map(c => ({
              text: c.name,
              value: `${c.key}${this.percent ? '_perc' : ''}`,
              width: `${100 / this.template.length}%`,
            }))
          : this.criteria.map(c => ({
              text: c.name,
              value: `${c.key}${this.percent ? '_perc' : ''}`,
              width: `${100 / this.criteria.length}%`,
              class: 'spanBlock',
            }));
      return [
        {
          text: 'Ime i prezime',
          value: 'name',
          width: '200px',
        },
        ...headers,
      ];
    },
    allEductionLevels() {
      return this.candidateFilters.education_level.length === this.vocational_qualifications.length;
    },
    icon() {
      if (this.allEductionLevels) return 'mdi-close-box';
      if (this.partialEductionLevels) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    partialEductionLevels() {
      const completeTest = this.allEductionLevels as boolean;
      return this.candidateFilters.education_level.length > 0 && !completeTest;
    },
    allCharsSelected() {
      return this.characteristsSelect.length === this.characteristics.length;
    },
    charIcon() {
      if (this.allCharsSelected) return 'mdi-close-box';
      if (this.partialCharSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    partialCharSelected() {
      const completeTest = this.allCharsSelected as boolean;
      return this.characteristsSelect.length > 0 && !completeTest;
    },
    allTempSelected() {
      return this.templateSelect.length === this.template.length;
    },
    tempIcon() {
      if (this.allTempSelected) return 'mdi-close-box';
      if (this.partialTempSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    partialTempSelected() {
      const completeTest = this.allTempSelected as boolean;
      return this.templateSelect.length > 0 && !completeTest;
    },
    allFilterChips() {
      const chips = [] as string[];

      if (this.candidateFilters.gender.male) chips.push('Pol: Muški');
      if (this.candidateFilters.gender.female) chips.push('Pol: Ženski');
      if (this.candidateFilters.education_level.length) {
        if (this.allEductionLevels) {
          chips.push('Stručna sprema: Sve opcije selektovane');
        } else {
          chips.push(
            ...this.candidateFilters.education_level.map(e => {
              const el = this.vocational_qualifications.find(vq => vq.value === e);
              return `Stručna sprema: ${el?.text}`;
            })
          );
        }
      }

      if (this.candidateFilters.birth_year.from || this.candidateFilters.birth_year.to)
        chips.push(
          `Godište: ${this.candidateFilters.birth_year.from.slice(-2)}-${this.candidateFilters.birth_year.to.slice(-2)}`
        );
      if (this.candidateFilters.total_seconds[0] || this.candidateFilters.total_seconds[1])
        chips.push(
          `Vreme izrade: ${this.candidateFilters.total_seconds[0]}-${this.candidateFilters.total_seconds[1]}m`
        );

      chips.push(
        ...this.characteristics
          .filter(c => c.value[0] !== 0 || c.value[1] !== 100)
          .map(c => `${c.name}: ${c.value[0]}-${c.value[1]}`)
      );
      chips.push(
        ...this.template
          .filter(t => t.value[0] !== 0 || t.value[1] !== 100)
          .map(t => `${t.name}: ${t.value[0]}-${t.value[1]}`)
      );

      return chips;
    },
  },
  watch: {
    applicationsOptions: {
      handler(val, oldVal) {
        if (
          JSON.stringify(val.sortBy) !== JSON.stringify(oldVal.sortBy) ||
          JSON.stringify(val.sortDesc) !== JSON.stringify(oldVal.sortDesc)
        ) {
          this.applicationsOptions.page = oldVal.page;
          this.applicationsOptions = { ...this.applicationsOptions };
        } else if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
          this.getData();
        }
      },
      deep: true,
    },
  },
  methods: {
    async addToReport() {
      const application_ids = this.selected.map((c: { id: number }) => c.id);
      const { status } = await API.post(`/psych/applications/${this.$route.params.id}/select/`, {
        application_ids,
      });

      if (status === 200) {
        this.candidatesInReport = Array.from(new Set([...this.candidatesInReport, ...application_ids]));
      }
    },
    async selectAllChars() {
      await this.$nextTick();
      if (!this.characteristsSelect.length) {
        this.characteristsSelect = [...this.characteristics.map((c, i) => i)];
      } else {
        this.characteristsSelect = [];
      }
    },
    async selectAllTemps() {
      await this.$nextTick();
      if (!this.templateSelect.length) {
        this.templateSelect = [...this.template.map((c, i) => i)];
      } else {
        this.templateSelect = [];
      }
    },
    async selectAllEducationLevels() {
      await this.$nextTick();
      if (!this.candidateFilters.education_level.length) {
        this.candidateFilters.education_level = [...this.vocational_qualifications.map(v => v.value)];
      } else {
        this.candidateFilters.education_level = [];
      }
    },
    checkMove(evt: { related: HTMLElement }) {
      if (evt.related.classList.contains('ignore-element')) {
        return -1;
      }
    },
    async applyFilters() {
      const filters = {} as { [key: string]: Array<number | string> | number | string };
      this.template.forEach(temp => {
        filters[temp.key] = temp.value;
      });
      this.characteristics.forEach(char => {
        filters[char.key] = char.value;
      });

      Object.entries(this.candidateFilters).forEach(([key, value]) => {
        if (key === 'gender') {
          const val = value as { male: boolean; female: boolean };
          const filter = [];
          if (val.male) filter.push('M');
          if (val.female) filter.push('F');
          if (filter.length) filters[key] = filter;
        } else if (key === 'birth_year') {
          const val = value as { from: string; to: string };
          const filter = ['1500', '3000'];
          if (val.from) filter[0] = val.from;
          if (val.to) filter[1] = val.to;
          filters[key] = filter;
        } else if (key === 'total_seconds') {
          if (!this.excludeTime) filters[key] = (value as number[]).map(v => v * 60);
        } else if (key === 'education_level') {
          filters[key] = (value as string[]).length
            ? (value as string[])
            : this.vocational_qualifications.map(v => v.value);
        } else {
          filters[key] = value as string | number[];
        }
      });

      const changedSortForCharateristics = this.characteristics.some((c, i) => c.order !== i + 1);
      const changedSortForTemplate = this.template.some((t, i) => t.order !== i + 1);

      if (changedSortForCharateristics || this.characteristicsSortOrder !== 'DESC') {
        filters['sort_list'] = this.characteristics.map(
          c => `${this.characteristicsSortOrder === 'DESC' ? '-' : ''}${c.key}`
        );
      } else if (changedSortForTemplate || this.templateSortOrder !== 'DESC') {
        filters['sort_list'] = this.template.map(t => `${this.templateSortOrder === 'DESC' ? '-' : ''}${t.key}`);
      }

      await this.getData(filters);
      const packedFilters = this.packFiltersToString();
      sessionStorage.setItem(`campaign_${this.$route.params.id}`, packedFilters);
    },
    async getData(filters = {}) {
      this.selected = [];
      const filter_string = Buffer.from(JSON.stringify(filters)).toString('base64');
      const { data } = await API.get(
        `/psych/${this.$route.params.id}/filter/?filter=${filter_string}&page=${this.applicationsOptions.page}&limit=${this.applicationsOptions.itemsPerPage}`
      );
      this.campaign = [...data.data];
      this.applicationsLength = data.result_count;
    },
    async createFile() {
      const filters = this.packFiltersToString();
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(filters);
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `${this.companyName} - ${this.positionName}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    async loadFile(e: InputEvent) {
      const reader = new FileReader();
      reader.onload = e => {
        const res = (e.target?.result as string) || '';
        this.loadFilters(res);
      };
      reader.readAsText(((e.target as HTMLInputElement).files || [])[0]);
    },
    loadFilters(filters: string) {
      const obj = JSON.parse(filters);
      // this.template.forEach(temp => {
      //   temp.value = obj[temp.key];
      // });
      // this.characteristics.forEach(char => {
      //   char.value = obj[char.key];
      // });
      this.candidateFilters = { ...obj.candidateFilters };
      this.template = [...obj.template];
      this.characteristics = [...obj.characteristics];
      // Object.keys(this.candidateFilters).forEach(key => {
      //   (this.candidateFilters as { [key: string]: unknown })[key] = obj[key];
      // });
    },
    packFiltersToString() {
      const filters = {
        candidateFilters: { ...this.candidateFilters },
        template: [...this.template],
        characteristics: [...this.characteristics],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as { [key: string]: any };
      return JSON.stringify(filters);
    },
  },
  async mounted() {
    const filters = sessionStorage.getItem(`campaign_${this.$route.params.id}`) || '';
    if (filters) {
      this.loadFilters(filters);
    }
    this.initLoaded = true;
    const { data: campaign } = await API.get(`/psych/campaigns/${this.$route.params.id}/`);
    if (campaign) {
      this.positionName = campaign.position;
      this.numCandidates = campaign.num_candidates;
      this.numSubmited = campaign.num_submitted;
      this.companyName = campaign.company.name;
      this.campaignTestType = campaign.test_type;

      const { data } = await API.get(`/psych/campaigns/${this.$route.params.id}/applications/selected/`);

      if (data) {
        this.candidatesInReport = Array.from(
          new Set([...this.candidatesInReport, ...data.map((c: { id: number }) => c.id)])
        );
      }
    }
    await this.applyFilters();
  },
});
