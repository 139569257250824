var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"circle",class:{
    clickable: this.size,
    active: _vm.active,
    'mr-4': !this.size,
  },style:({
    width: this.size || '3em',
    height: this.size || '3em',
    borderWidth: this.size ? '1px' : '2px',
  }),on:{"click":function($event){return _vm.$emit('click', _vm.number)}}},[_c('span',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.number)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }