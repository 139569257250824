














































































import API, { BASE_URL } from '@/plugins/axios';
import { VForm } from '@/plugins/vuetify';
import Vue from 'vue';
import NumberCircle from '@/components/NumberCircle.vue';
import FileDrop from '@/components/FileDrop.vue';
export default Vue.extend({
  name: 'AdminHome',
  components: {
    NumberCircle,
    FileDrop,
  },
  data: () => ({
    form: {
      name: '',
      pib: '',
      apr_id: '',
      contact_email: '',
      contact_name: '',
      contact_phone: '',
      location: '',
      logo: null as unknown,
    },
    logo_url: '',
    rules: {},
    validForm: false,
    isSent: false,
    cancelUrl: '/',
  }),
  computed: {},
  methods: {
    async register() {
      if (!(this.$refs.form as VForm).validate()) return;
      const form = new FormData();
      form.append('name', this.form.name);
      form.append('pib', this.form.pib);
      form.append('apr_id', this.form.apr_id);
      form.append('location', this.form.location);
      form.append('contact_email', this.form.contact_email);
      form.append('contact_name', this.form.contact_name);
      form.append('contact_phone', this.form.contact_phone);
      if (this.form.logo) form.append('logo', this.form.logo as Blob);

      if (this.$route.params.id) {
        const { status } = await API.patch(`/administration/companies/${this.$route.params.id}/`, form);
        if (status === 200) {
          this.isSent = true;
          this.$router.push(`/admin/company/${this.$route.params.id}`);
        } else {
          console.error('something went wrong');
        }
      } else {
        const { status, data } = await API.post('/administration/companies/', form);
        if (status === 201) {
          this.isSent = true;
          this.$router.push(`/admin/company/${data.id}`);
        } else {
          console.error('something went wrong');
        }
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!v.length || error;
    },
    onFileSelected(files: unknown[]) {
      this.form.logo = files.length ? files[0] : null;
      if (this.form.logo) {
        const reader = new FileReader();
        reader.onload = e => {
          this.logo_url = (e.target?.result as string) || '';
        };
        reader.readAsDataURL(this.form.logo as Blob);
      } else {
        this.logo_url = '';
      }
    },
  },
  async mounted() {
    if (this.$route.params.id) {
      this.cancelUrl = `/admin/company/${this.$route.params.id}`;
      const { data } = await API.get(`/administration/companies/${this.$route.params.id}/`);
      this.form = { ...data, logo: '' };
      if (data.logo) this.logo_url = `${BASE_URL}${data.logo}`;
    }
    this.rules = {
      name: [this.isPopulated('Ime mora biti popunjeno')],
      pib: [this.isPopulated('PIB mora biti popunjen')],
      apr_id: [this.isPopulated('Matični broj mora biti popunjen')],
      location: [this.isPopulated('Grad mora biti popunjen')],
      contact_email: [
        this.isPopulated('Email mora biti popunjen'),
        (v: string) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Email mora biti validan',
      ],
      contact_name: [this.isPopulated('Ime mora biti popunjeno')],
      contact_phone: [this.isPopulated('Telefon mora biti popunjen')],
    };
  },
});
