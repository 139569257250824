



















































import API, { BASE_URL } from '@/plugins/axios';
import Vue from 'vue';
import { Campaign } from '@/types';
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
import relativeTime from 'dayjs/plugin/relativeTime';
import { dateSort, stringSort } from '@/utils/utils';
import { testType } from '@/utils/data';
dayjs.extend(relativeTime);
dayjs.locale('sr');
export default Vue.extend({
  name: 'CompanyList',
  data: () => ({
    BASE_URL: BASE_URL,
    campaigns: [] as Campaign[],
    headers: [
      { text: 'Kampanja', value: 'position' },
      {
        text: 'Kompanija',
        value: 'company',
        sort: (a: Campaign['company'], b: Campaign['company']) => stringSort(a.name, b.name),
      },
      {
        text: 'Datum',
        value: 'created_at',
        sort: dateSort,
      },
      { text: 'Tip kampanje', value: 'campaign_type' },
      { text: 'Tip testa', value: 'test_type' },
      { text: 'Status', value: 'state' },
      { text: 'Do kraja', value: 'ends_at', sort: dateSort },
    ],
    campaign_type: {
      new_position: 'Nova pozicija',
      internal_test: 'Interni test',
      company_profile: 'Profil kompanije',
    },
    testType: [...testType],
    page: 1,
  }),
  computed: {},
  methods: {
    formatedDate(date: string) {
      return dayjs(date).format('DD/MM/YY');
    },
    until_end(end: string) {
      return end ? dayjs().to(end, true) : '';
    },
    rowClicked({ id }: { id: number }) {
      this.$router.push(`/admin/campaign/${id}`);
    },
    async getData() {
      const { data } = (await API.get(`/administration/campaigns/?page=${this.page}&limit=10`)) as {
        data: { data: Campaign[]; next_page?: number };
      };
      this.campaigns = [
        ...this.campaigns,
        ...data.data.map(item => ({
          ...item,
          ends_at: item.state === 'ready' ? item.ends_at : '',
        })),
      ];
      if (data.next_page) {
        this.page++;
        this.getData();
      }
    },
  },
  async mounted() {
    await this.getData();
  },
});
