export const vocationalQualifications = [
  { text: '(1) Osnovno obrazovanje i vaspitanje', value: 'Osnovno obrazovanje i vaspitanje' },
  { text: '(1) Osnovno obrazovanje odraslih', value: 'Osnovno obrazovanje odraslih' },
  { text: '(1) Osnovno muzičko/baletsko obrazovanje', value: 'Osnovno muzičko/baletsko obrazovanje' },
  {
    text: '(3) Srednje stručno obrazovanje (3 godine)',
    value: 'Srednje stručno obrazovanje u trajanju od tri godine',
  },
  { text: '(3) Neformalno obrazovanje odraslih', value: 'Neformalno obrazovanje odraslih (min 960 sati obuke)' },
  {
    text: '(4) Srednje obrazovanje (4 godine)',
    value: 'Srednje obrazovanje u trajanju od četiri godine (stručno, umetničko, gimnazijsko)',
  },
  { text: '(5) Majstorsko i specijalističko obrazovanje', value: 'Majstorsko i specijalističko obrazovanje' },
  { text: '(6.1) Osnovne akademske studije (OAS, 180 ESPB)', value: 'Osnovne akademske studije(OAS, 180 ESPB)' },
  { text: '(6.1) Osnovne stukovne studije (OSS, 180 ESPB)', value: 'Osnovne strukovne studije (OSS, 180 ESPB)' },
  { text: '(6.2) Osnovne akademske studije (OAS, 240 ESPB)', value: 'Osnovne akademske studije(OAS, 240)' },
  {
    text: '(6.2) Specijalističke strukovne studije I stepena (SSS, 240 ESPB)',
    value:
      'Specijalističke strukovne studije I stepena(SSS, 180+60 ESPB, stečene u skladu sa Zakonom o visokom obrazovanju koji je na snazi od 07.10.2017.)',
  },
  {
    text: '(7.1) Integrisane akademske studije (IAS, MAX 360 ESPB)',
    value: 'Integrisane akademske studije (IAS, maks. 360 ESPB)',
  },
  {
    text: '(7.1) Master akademske studije (MAS, 300 ESPB)',
    value: 'Master akademske studije (MAS,180+120 ili 240+60 ESPB)',
  },
  { text: '(7.1) Master strukovne studije (MSS, 120 ESPB)', value: 'Master strukovne studije (MSS, 120 ESPB)' },
  {
    text: '(7.2) Specijalističke akademske studije (SAS, 60 ESPB)',
    value: 'Specijalističke akademske studije (SAS, 60 ESPB)',
  },
  { text: '(8) Doktorske studije (DS, 180 ESPB)', value: 'Doktorske studije (DS, 180 ESPB)' },
];

export const characteristics = [
  {
    name: 'Istrajnost',
    value: [40, 60],
    key: 'IST',
    order: 1,
  },
  {
    name: 'Proaktivnost',
    value: [40, 60],
    key: 'PRO',
    order: 2,
  },
  {
    name: 'Odgovornost',
    value: [40, 60],
    key: 'ODG',
    order: 3,
  },
  {
    name: 'Inovativnost',
    value: [40, 60],
    key: 'INO',
    order: 4,
  },
  {
    name: 'Efikasnost',
    value: [40, 60],
    key: 'EFI',
    order: 5,
  },
  {
    name: 'Veštine komunikacije',
    value: [40, 60],
    key: 'VK',
    order: 6,
  },
  {
    name: 'Saradljivost',
    value: [40, 60],
    key: 'SAR',
    order: 7,
  },
];

export const template = [
  {
    name: 'Promišljenost',
    value: [40, 60],
    key: 'promisljenost',
    order: 1,
  },
  {
    name: 'Pragmatičnost',
    value: [40, 60],
    key: 'pragmaticnost',
    order: 2,
  },
  {
    name: 'Racionalnost',
    value: [40, 60],
    key: 'racionalnost',
    order: 3,
  },
  {
    name: 'Sistematičnost',
    value: [40, 60],
    key: 'sistematicnost',
    order: 4,
  },
  {
    name: 'Preduzimljivost',
    value: [40, 60],
    key: 'preduzimljivost',
    order: 5,
  },
  {
    name: 'Analitičnost',
    value: [40, 60],
    key: 'analiticnost',
    order: 6,
  },
];

export const criteria = [
  { name: 'Brzina', key: 'brzina' },
  { name: 'Preuzimanje odgovornosti', key: 'preuzimanje' },
  { name: 'Samostalnost', key: 'samostalnost' },
  { name: 'Informisanost', key: 'informisanost' },
  { name: 'Praktičnost', key: 'prakticnost' },
  { name: 'Objektivnost', key: 'objektivnost' },
  { name: 'Etičnost', key: 'eticnost' },
  { name: 'Originalnost', key: 'originalnost' },
  { name: 'Preuzimanje rizika', key: 'rizik' },
  { name: 'Dvoumljenje', key: 'dvoumljenje' },
  { name: 'Težina', key: 'tezina' },
  { name: 'Voljnost', key: 'voljnost' },
  { name: 'Socijalna potvrda', key: 'socijalna' },
  { name: 'Emocionalnost', key: 'emocionalnost' },
  { name: 'Kvalitet rešenja', key: 'kvalitet' },
  { name: 'Racionalnost', key: 'racionalnost' },
  { name: 'Planiranje', key: 'planiranje' },
  { name: 'Korišćenje iskustva', key: 'koriscenje' },
];

export const testType = [
  'Istrajnost',
  'Proaktivnost',
  'Odgovornost',
  'Inovativnost',
  'Efikasnost',
  'Veština komunikacije',
];
