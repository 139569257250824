





































































































































































import Vue from 'vue';
import axios from 'axios';
import { VForm } from '@/plugins/vuetify';
import { BASE_URL } from '@/plugins/axios';
import NumberCircle from '@/components/NumberCircle.vue';
import { vocationalQualifications } from '@/utils/data';
export default Vue.extend({
  components: { NumberCircle },
  name: 'Register',
  data: () => ({
    form: {
      email: '',
      password: '',
      passwordConfirmation: '',
      first_name: '',
      parents_name: '',
      last_name: '',
      date_of_birth: '',
      id_card_no: '',
      sex: '',
      vocational_qualification: '',
      profession: '',
      last_finished_school: '',
      phone: '',
    },
    rules: {},
    validForm: false,
    vocational_qualifications: [...vocationalQualifications],
    isSent: false,
    dialog: false,
  }),
  methods: {
    async register() {
      if (!(this.$refs.form as VForm).validate()) return;
      const { status } = await axios.post(`${BASE_URL}/auth/candidate_register/`, {
        email: this.form.email,
        password: this.form.password,
        candidate: {
          forename: this.form.first_name,
          surname: this.form.last_name,
          birth_year: this.form.date_of_birth,
          id_card_no: this.form.id_card_no,
          gender: this.form.sex,
          education_level: this.form.vocational_qualification,
          vocation: this.form.profession,
          last_education: this.form.last_finished_school,
          parent_name: this.form.parents_name,
          contact_phone: this.form.phone,
          campaign_url: localStorage.getItem('campaign_uuid'),
        },
      });
      if (status === 201) {
        this.isSent = true;
      } else {
        console.error('something went wrong');
      }
    },
    isPopulated(error: string) {
      return (v: string) => !!v.length || error;
    },
    isNumber(error: string) {
      return (v: string) => /^\d+$/.test(v) || error;
    },
    passwordsAreEqual() {
      return (v: string) => v === this.form.password || 'Lozinka i ponovljena lozinka moraju biti iste';
    },
  },
  mounted() {
    this.rules = {
      email: [
        this.isPopulated('Email mora biti popunjen'),
        (v: string) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Email mora biti validan',
      ],
      password: [
        this.isPopulated('Lozinka mora biti popunjena'),
        (v: string) => /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(v) || 'Lozinka mora da ima slova i brojeve',
      ],
      passwordConfirmation: [this.isPopulated('Lozinka mora biti popunjena'), this.passwordsAreEqual()],
      first_name: [this.isPopulated('Ime mora biti popunjeno')],
      parents_name: [this.isPopulated('Ime jednog od roditelja mora biti popunjeno')],
      last_name: [this.isPopulated('Prezime mora biti popunjeno')],
      date_of_birth: [
        this.isPopulated('Godina rođenja mora biti popunjena'),
        (v: string) =>
          (Number.isInteger(Number(v)) &&
            Number(v) < new Date().getFullYear() &&
            Number(v) > new Date().getFullYear() - 100) ||
          'Godina rođenja mora biti validna',
      ],
      id_card_no: [
        this.isPopulated('Broj lične karte mora biti popunjen'),
        this.isNumber('Broj lične karte mora biti broj'),
      ],
      sex: [this.isPopulated('Pol mora biti izabran')],
      vocational_qualification: [this.isPopulated('Stručna sprema mora biti izabrana')],
      profession: [this.isPopulated('Zanimanje mora biti popunjeno')],
      last_finished_school: [this.isPopulated('Poslednja završena škola mora biti popunjena')],
      phone: [this.isPopulated('Telefon mora biti popunjen')],
    };
  },
});
